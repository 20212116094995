import { Query } from "@apollo/react-components";
import { Button, notification } from "antd";
import { gql } from "apollo-boost";
import React, { Component } from "react";
import CampaignConfiguration from "../../components/seller/CampaignConfiguration";
import ProductBaseSelect from "../../components/seller/ProductBaseSelect";
import PageTitle from "../../components/shared/PageTitle";
import Templates from "../../components/templates/Templates";
import { AppContext } from "../../context";
import { productBaseFragment } from "../../fragments/index";
// import _ from "lodash";
import { getParam } from "../../helper";
import { getProductBaseVariants } from "../../components/seller/utils";
import ProductbaseSelectV2 from "../../components/seller/ProductBaseSelectV2";

const TEMPLATE_BY_ID = gql`
  query getTemplateByID($id: ID!) {
    getTemplateByID(id: $id) {
      id
      name
      slug
      grouping
      defaultMockupIds
      data {
        personalized
        productBases {
          ...ProductBasse
        }
        ProductBaseIds
        fields {
          name
          title
          type
          extraFee
          configure
          sorting
        }
        products {
          title
          description
          productbaseId
          shortDescription
          tagIds
          tags {
            id
            name
          }
          variants {
            salePrice
            regularPrice
            productBaseVariantID
            disabled
          }
          collectionIds
          collections {
            id
            name
          }
        }
      }
    }
  }
  ${productBaseFragment}
`;

export const QUERY_DP_BY_BASE_SELECTED = gql`
  query productBaseByID($id: ID!) {
    productBaseByID(id: $id) {
      id
      designPositions {
        id
        image {
          id
          thumbnailUrl
          url
        }
        name
        displayName
        sorting
        description
        artworkGuidelines {
          description
          file {
            url
            thumbnailUrl
            name
          }
        }
      }
    }
  }
`;

class AddCampaignProduct extends Component {
  static contextType = AppContext;
  state = {
    step: 1,
    selectedProductBases: [],
    fulfillmentId: null,
    template: null,
  };
  handleOnProductBaseChange = (selectedProductBases) => {
    this.setState({
      selectedProductBases,
      step: selectedProductBases.length === 0 ? 1 : this.state.step,
    });
  };

  handleClickContinue = (client) => async () => {
    const { selectedProductBases } = this.state;

    if (selectedProductBases.length === 0) {
      notification.error({
        message: "Product base is required!",
      });
      return;
    }

    const baseIds = selectedProductBases.map(({ id }) => id);
    // const bases = await Promise.all(
    //   baseIds.map(async (id) => {
    //     const { data } = await client.query({
    //       query: QUERY_DP_BY_BASE_SELECTED,
    //       variables: { id },
    //       fetchPolicy: "no-cache",
    //     });
    //     return data?.productBaseByID;
    //   })
    // );

    // const mapBase = bases.reduce((acc, { id, ...rest }) => {
    //   acc[id] = { ...rest };
    //   return acc;
    // }, {});

    // const newBaseSelected = selectedProductBases.map((base) => {
    //   const { id } = base;
    //   const current = mapBase[id];
    //   if (current && current.designPositions) {
    //     base.designPositions = current.designPositions;
    //   }

    //   return base;
    // });

    const values = await getProductBaseVariants(baseIds);
    const newBaseSelected = selectedProductBases.map((base) => {
      const { id } = base;
      if (values || values[id]) {
        base = {
          ...base,
          ...values[id],
        };
      }

      return base;
    });
    this.setState({
      step: 2,
      selectedProductBases: newBaseSelected,
    });
  };

  render() {
    const { step, selectedProductBases } = this.state;
    const { clickSave, handleBtnSave, match } = this.props;
    const { id } = match.params;

    const { currentUser } = this.context;
    const currentParam = getParam(currentUser);

    return (
      <Query
        query={TEMPLATE_BY_ID}
        variables={{ id: id }}
        onCompleted={(res) => {
          if (id && res?.getTemplateByID != null) {
            const { ProductBaseIds, productBases } =
              res.getTemplateByID?.data || {};
            const mapBases = (productBases || []).reduce((acc, item) => {
              if (!item) return acc;
              acc[item.id] = item;
              return acc;
            }, {});
            const bases = (ProductBaseIds || [])
              .map((id) => mapBases[id])
              .filter(Boolean);

            this.setState({
              template: res.getTemplateByID,
              selectedProductBases:
                bases?.length > 0
                  ? bases
                  : res.getTemplateByID.data.productBases,
              step: 2,
            });
          }
        }}
        skip={!id}
      >
        {({ client }) => {
          return (
            <div>
              {step === 1 && (
                <div>
                  <PageTitle
                    action={
                      <Button
                        onClick={this.handleClickContinue(client)}
                        // onClick={() => {
                        //   if (selectedProductBases.length === 0) {
                        //     notification.error({
                        //       message: "Product base is required!",
                        //     });
                        //     return;
                        //   }
                        //   this.setState({ step: 2 });
                        // }}
                        type="primary"
                        disabled={!selectedProductBases.length}
                      >
                        Continue to Add Campaign
                      </Button>
                    }
                    link={`/${currentParam}/products`}
                    subTitle={"Products"}
                    title={"Add Campaign"}
                  />
                  {/* <ProductBaseSelect
                    selectedProductBases={this.state.selectedProductBases}
                    onFulfillmentChange={(v) =>
                      this.setState({ fulfillmentId: v })
                    }
                    fulfillmentId={this.state.fulfillmentId}
                    noVariantDisabled={true}
                    value={selectedProductBases}
                    onChange={this.handleOnProductBaseChange}
                    multiple={true}
                    onViewTemplates={() => {
                      this.setState({
                        step: 3,
                      });
                    }}
                  /> */}
                  <ProductbaseSelectV2
                    selectedProductBases={this.state.selectedProductBases}
                    onFulfillmentChange={(v) =>
                      this.setState({ fulfillmentId: v })
                    }
                    fulfillmentId={this.state.fulfillmentId}
                    value={selectedProductBases}
                    onChange={this.handleOnProductBaseChange}
                    multiple={true}
                    onViewTemplates={() => {
                      this.setState({
                        step: 3,
                      });
                    }}
                  />
                </div>
              )}
              {step === 2 && (
                <CampaignConfiguration
                  client={client}
                  currentParam={currentParam}
                  onViewTemplates={() => {
                    this.setState({ step: 3 });
                  }}
                  onAddMore={() => {
                    this.setState({
                      step: 1,
                    });
                  }}
                  onChange={this.handleOnProductBaseChange}
                  template={this.state.template}
                  productBases={selectedProductBases}
                  clickSave={clickSave}
                  handleLoading={(loading) => {
                    let { handleLoading } = this.props;
                    if (handleLoading) {
                      handleLoading(loading);
                    }
                  }}
                  handleBtnSave={(value, aggregations) => {
                    aggregations = {
                      ...aggregations,
                      step: this.state.step,
                      link: "/seller/products",
                    };
                    if (handleBtnSave) {
                      handleBtnSave(value, aggregations);
                    }
                  }}
                />
              )}
              {step === 3 && (
                <div>
                  <PageTitle
                    action={
                      <Button
                        onClick={() => {
                          this.setState({ step: 1 });
                        }}
                        type="primary"
                      >
                        Start from Scratch
                      </Button>
                    }
                    subTitle={"Products"}
                    title={"Templates"}
                    link={`/${currentParam}/products`}
                  />
                  <Templates
                    onSelect={(template) => {
                      this.setState({
                        template: template,
                        selectedProductBases: template.data.productBases,
                        step: 2,
                      });
                    }}
                  />
                </div>
              )}
            </div>
          );
        }}
      </Query>
    );
  }
}

export default AddCampaignProduct;
