import { useMutation } from "@apollo/react-hooks";
import { Button, ButtonGroup, Page, Toast } from "@shopify/polaris";
import { useParams, useLocation } from "react-router-dom";
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import styled from "styled-components";
import { ProductsPolaris } from "../../components/product/ProductsPolaris";
import { ScreenOptionsPolaris } from "../../components/shared/ScreenOptionsPolaris";
import { AppContext } from "../../context";
import { EXPORT_PRODUCTS } from "../../graphql/mutations";
import {
  checkRole,
  getCookie,
  getParam,
  getPathFromRouter,
  handleError,
  isPME_TEAM,
  reducerFn,
  setCookie,
} from "../../helper";
import useToggle from "../../hooks/useToggle";
import _ from "lodash";

const Container = styled.div`
  margin: -1rem;
  @media (min-width: 640px) {
    margin: -1.5rem;
  }
`;

const URL_PARAMS = {
  keys: ["limit", "paged", "sort", "sortBy"],
  values: ["10", "1", "DESC", "total_orders"],
};

export const ProductsPagePolaris = (...props) => {
  // State
  const [screenOptions, setScreenOptions] = useState([]);
  const [userId, setUserId] = useState(null);
  const [state, setState] = useReducer(reducerFn, {
    products: [],
  });

  // Get data
  useEffect(() => {
    let id = getCookie("userId");
    let options = getCookie(`screenOption_Products_${id}`);
    if (options) {
      options = options.split(",");
      setScreenOptions(options);
    }
    setUserId(id);
  }, []);

  useEffect(() => {
    if (userId) {
      setCookie(`screenOption_Products_${userId}`, screenOptions, 100);
    }
  }, [userId, screenOptions]);

  return (
    <Container>
      <div>
        <ScreenOptionsPPolaris
          defaultValue={screenOptions}
          onChange={(value) => setScreenOptions(value)}
        />
      </div>
      <ProductsPolarisContextProvider
        screenOptions={screenOptions}
        setState={setState}
        {...state}
      >
        <ProductsPagePolarisInner {...props} />
      </ProductsPolarisContextProvider>
    </Container>
  );
};

const ProductsPagePolarisInner = (...props) => {
  const path = useMemo(() => {
    return getPathFromRouter(props != null ? [props] : []);
  }, [props]);

  // Context
  const { currentUser } = useContext(AppContext);
  const { isMarketplaceManager, isStoreManager } = checkRole(currentUser);
  const hideFilterStore = isStoreManager || isMarketplaceManager;
  const isPME = isPME_TEAM(currentUser);

  const { products } = useProductsPolarisContext();

  const currentParam = getParam(currentUser);
  const [exportState, setExportState] = React.useReducer(reducerFn, {
    show: false,
    showExport: false,
    filter: {},
  });

  const { toggleActive, setNotify } = useProductsPolarisContext();

  const [exportProducts, { loading }] = useMutation(EXPORT_PRODUCTS, {
    onCompleted: (res) => {
      if (res?.exportProducts) {
        window.location.href = res.exportProducts;
        setNotify({ msg: "Export products success", err: false });
      }
    },
    onError: (err) => {
      setNotify({ msg: handleError(err?.toString()), err: true });
    },
  });

  React.useEffect(() => {
    const s = window.location.search;
    const urlParams = new URLSearchParams(s);
    const entries = urlParams.entries();
    for (let entry of entries) {
      if (!entry || entry.length === 0) continue;
      const [key, value] = entry;
      if (
        !URL_PARAMS.keys.includes(key) ||
        !URL_PARAMS.values.includes(value)
      ) {
        setExportState({ show: true });
        break;
      }
    }
  }, []);
  const handleExport = React.useCallback(() => {
    if (exportState.filter != null) {
      toggleActive && toggleActive(true);
      setNotify && setNotify({ msg: null, err: false });

      exportProducts({
        variables: {
          filter: exportState.filter,
        },
      });
    }
  }, [exportState.filter, toggleActive]);

  const handleExportTiktok = React.useCallback(() => {
    if (!products || !Array.isArray(products) || products.length === 0) return;
    const headers = ["Title"];

    // Get the values of the object
    const values = [];
    let countImg = 1;
    for (let p of products) {
      const images = p.images || [];
      const len = images.length;
      if (len > countImg) {
        countImg = len;
      }

      let title = `\"${p.title}\"`;
      values.push([title, ...images].join(","));
    }

    for (let i = 0; i < countImg; i++) {
      headers.push(`Image ${i + 1}`);
    }

    const csvdata = [headers.join(","), values.join("\n")].join("\n");

    const blob = new Blob([csvdata], { type: "text/csv" });

    // Create a URL for the Blob
    const url = URL.createObjectURL(blob);

    // Create an anchor tag for downloading
    const a = document.createElement("a");

    // Set the URL and download attribute of the anchor tag
    a.href = url;
    a.download = "export-product-for-tiktok.csv";

    // Trigger the download by clicking the anchor tag
    a.click();
  }, [products]);

  const buttonsMarkup = React.useMemo(() => {
    return (
      <ButtonGroup>
        <Button primary url={`/${currentParam}/products/add`}>
          Add Product
        </Button>
        <Button primary url={`/${currentParam}/products/add-campaign`}>
          Add Campaign
        </Button>
        {exportState.show && (
          <Button
            children="Export products"
            onClick={handleExport}
            loading={loading}
          />
        )}
        {isPME && (
          <Button
            children="Export product for Tiktok"
            onClick={handleExportTiktok}
            loading={loading}
          />
        )}
      </ButtonGroup>
    );
  }, [
    currentParam,
    exportState.show,
    handleExport,
    loading,
    isPME,
    handleExportTiktok,
  ]);

  return (
    <Page title="All Products" fullWidth primaryAction={buttonsMarkup}>
      <ProductsPolaris
        hideFilterStore={hideFilterStore}
        isMarketplaceManager={isMarketplaceManager}
        path={path}
        setExportState={setExportState}
      />
    </Page>
  );
};

const ProductsPolarisContext = createContext(null);

function ProductsPolarisContextProvider({ children, ...props }) {
  const [active, toggleActive] = useToggle(false);
  const [notify, setNotify] = useState({ msg: null, err: false });

  // Provider
  const bag = useMemo(
    () => ({ toggleActive, setNotify, ...props }),
    [props, toggleActive, setNotify],
  );

  // Markup
  const toastMarkup = active && notify.msg && (
    <Toast
      content={notify.msg}
      error={notify.err}
      onDismiss={toggleActive}
      duration={3000}
    />
  );

  return (
    <ProductsPolarisContext.Provider value={bag}>
      {toastMarkup}
      {children}
    </ProductsPolarisContext.Provider>
  );
}

export function useProductsPolarisContext() {
  return useContext(ProductsPolarisContext);
}

export const SHORT_TITLE = "shortTitle";
export const PUSHED_SALE_CHANNELS = "pushedSaleChannel";
export const MARK_AS_PUSHED = "markAsPushed";
const SCREEN_OPTIONS = [
  {
    value: SHORT_TITLE,
    label: "Show Titles",
  },
  {
    value: PUSHED_SALE_CHANNELS,
    label: "Pushed Sale Channels",
  },
  {
    value: MARK_AS_PUSHED,
    label: "Mark as Pushed",
  },
];

function ScreenOptionsPPolaris(props) {
  return <ScreenOptionsPolaris {...props} options={SCREEN_OPTIONS} />;
}
