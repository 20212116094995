import { Button, Modal } from "antd";
import React from "react";
import useToggle from "../../../hooks/useToggle";
import CSVReader from "react-csv-reader";
import { ATTR_MODEL } from "../utils";
import { arrInvalid } from "../../../helper";

const papaparseOptions = {
  header: true,
  dynamicTyping: true,
  skipEmptyLines: true,
  transformHeader: (header) => header.toLowerCase().replace(/\W/g, "_"),
};

export default function AddVariantsFromCSV({ onChange }) {
  const [open, toggleOpen] = useToggle(false);

  const handleForce = React.useCallback(
    (rows) => {
      if (!rows || rows.length === 0) return;
      const attrs = new Map();
      const vars = new Map();

      /**
     * {
      color: "ASH",
      product_title: "Youth T-shirt Gildan 5000B",
      size: "XS",
      sku: "G5000BUS_101",
      }
     */
      for (let r of rows) {
        vars.set(`${r.color}|${r.size}`, r);
      }

      const newVariants = [];
      const newVars = Array.from(vars.values());
      for (let i = 0; i < newVars.length; i++) {
        const r = newVars[i];
        const attributes = [
          { name: "Size", slug: "size", option: r.size },
          { name: "Color", slug: "color", option: r.color },
        ];
        newVariants.push({
          attributes,
          fulfillmentProductId: r.sku,
          regularPrice: 0,
          salePrice: 0,
          sellerPrice: 0,
          sorting: i,
        });

        for (let v of ["color", "size"]) {
          const attr = attrs.get(v);
          if (attr) {
            attrs.set(v, attr.add(r[v]));
          } else {
            attrs.set(v, new Set([r[v]]));
          }
        }
      }

      const newAttributes = [];
      for (let [slug, options] of Array.from(attrs.entries())) {
        const name = slug.charAt(0).toUpperCase() + slug.slice(1).toLowerCase();
        const opts = Array.from(options.values());

        newAttributes.push({ name, slug, options: opts });
      }
      onChange &&
        onChange({ variants: newVariants, attributes: newAttributes });
      toggleOpen(false);
    },
    [onChange],
  );

  // https://merchize.com/product/classic-unisex-crew-neck-sweatshirt-us/
  const handleFileChange = React.useCallback(
    async (event) => {
      let file;

      // onchange
      if (event.target?.files?.length > 0) {
        file = event.target.files[0];
      }
      // ondrop
      else if (event.dataTransfer?.files?.length > 0) {
        file = event.dataTransfer.files[0];
      }

      if (!file) return;
      try {
        const { variants, attributes } = await readFileImpl(file);
        if (arrInvalid(variants) || arrInvalid(attributes))
          throw Error("variant is empty");

        onChange && onChange({ variants, attributes });
        toggleOpen(false);
      } catch (err) {
        console.error("catch error: ", err?.toString());
      }
    },
    [onChange],
  );

  return (
    <>
      <Button children="Add Variants by CSV" onClick={toggleOpen} />
      <Modal
        title="Choose file"
        visible={open}
        onCancel={toggleOpen}
        footer={false}
      >
        {/* <CSVReader
          cssClass="csv-reader-input"
          label={
            <div style={{ display: "block" }}>
              <span>Choose file CSV</span>
            </div>
          }
          onFileLoaded={handleForce}
          parserOptions={papaparseOptions}
          inputId="ObiWan"
          inputName="ObiWan"
        /> */}

        <input
          type="file"
          onChange={handleFileChange}
          onDrop={handleFileChange}
          onDragOver={(e) => e.preventDefault()}
        />
      </Modal>
    </>
  );
}

// Handle file upload for Marchize
async function readFileImpl(file) {
  return new Promise((resolve, reject) => {
    if (file == null) reject("file is null");
    const reader = new FileReader();
    reader.onload = function (evt) {
      const bstr = evt.target.result;
      const tDecode = new TextDecoder("utf-8");
      const str = tDecode.decode(bstr);

      // line:
      //    + 1: base_cost
      //    + 2: title
      if (!str || typeof str !== "string") {
        reject("could not read data");
      }

      let [, header, ...content] = str.split(/\n/);
      const pt_type = ATTR_MODEL.type.pt;
      const pt_color = ATTR_MODEL.color.pt;
      const pt_size = ATTR_MODEL.size.pt;
      const pt_sku = /sku variant/i;
      const pt_tier = /tier/i;
      const pt_shipping = /shipping fee/i;

      const headerSplit = (header || "").split(",");
      const typeIndex = headerSplit.findIndex((i) => !!i.match(pt_type));
      const colorIndex = headerSplit.findIndex((i) => !!i.match(pt_color));
      const sizeIndex = headerSplit.findIndex((i) => !!i.match(pt_size));
      const skuIndex = headerSplit.findIndex((i) => !!i.match(pt_sku));
      const tierIndex = headerSplit.findIndex((i) => !!i.match(pt_tier));
      const shippingIndex = headerSplit.findIndex(
        (i) => !!i.match(pt_shipping),
      );
      content = content.filter(Boolean);

      const attrMap = {};
      if (content.length === 0) {
        reject("content is empty");
      }

      const newVariants = [];
      for (let i = 0; i < content.length; i++) {
        const line = content[i];

        if (!line || typeof line !== "string") continue;

        const rows = line.split(",");
        const typeVal = rows[typeIndex];
        const colorVal = rows[colorIndex];
        const sizeVal = rows[sizeIndex];
        const skuVal = rows[skuIndex];
        const tierVal = rows[tierIndex];
        const shippingVal = rows[shippingIndex];

        const attrs = [];
        if (typeVal) {
          attrs.push({
            name: ATTR_MODEL.type.name,
            slug: ATTR_MODEL.type.slug,
            option: typeVal,
          });

          const attCache = attrMap[ATTR_MODEL.type.slug];
          if (!attCache) attrMap[ATTR_MODEL.type.slug] = new Set();
          attrMap[ATTR_MODEL.type.slug].add(typeVal);
        }

        if (colorVal) {
          attrs.push({
            name: ATTR_MODEL.color.name,
            slug: ATTR_MODEL.color.slug,
            option: colorVal,
          });

          const attCache = attrMap[ATTR_MODEL.color.slug];
          if (!attCache) attrMap[ATTR_MODEL.color.slug] = new Set();
          attrMap[ATTR_MODEL.color.slug].add(colorVal);
        }

        if (sizeVal) {
          attrs.push({
            name: ATTR_MODEL.size.name,
            slug: ATTR_MODEL.size.slug,
            option: sizeVal,
          });
          const attCache = attrMap[ATTR_MODEL.size.slug];
          if (!attCache) attrMap[ATTR_MODEL.size.slug] = new Set();
          attrMap[ATTR_MODEL.size.slug].add(sizeVal);
        }

        const count = Number(tierVal || 0) + Number(shippingVal || 0);
        const sellerPrice = count.toFixed(2);
        const regularPrice = (sellerPrice * 3).toFixed(2);

        newVariants.push({
          sorting: i,
          attributes: attrs,
          regularPrice: +regularPrice,
          sellerPrice: +sellerPrice,
          salePrice: 0,
          fulfillmentProductId: skuVal,
        });
      }

      const newAttributes = [];
      if (Object.keys(attrMap).length > 0) {
        for (let [key, value] of Object.entries(attrMap)) {
          const name = key.charAt(0).toUpperCase() + key.slice(1).toLowerCase();
          const arrVal = Array.from(value);
          newAttributes.push({ name, slug: key, options: arrVal });
        }
      }

      resolve({ variants: newVariants, attributes: newAttributes });
    };
    reader.readAsArrayBuffer(file);
  });
}
