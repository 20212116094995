import { gql } from "apollo-boost";
import { get } from "lodash";
import { productBaseFragment } from "../../../fragments";

const GET_TEMPLATE_BY_ID = gql`
  query getTemplateByID($id: ID!) {
    getTemplateByID(id: $id) {
      id
      name
      slug
      grouping
      isProductTemplate
      defaultMockupIds
      productBases {
        id
        mockupTemplates {
          id
          originTemplateID
          isDefault
        }
      }
      data {
        personalized
        productBases {
          ...ProductBasse
        }
        ProductBaseIds
        fields {
          name
          title
          type
          extraFee
          configure
          sorting
        }
        products {
          title
          description
          productbaseId
          shortDescription
          tagIds
          tags {
            id
            name
          }
          variants {
            salePrice
            regularPrice
            productBaseVariantID
            disabled
          }
          collectionIds
          collections {
            id
            name
          }
        }
      }
    }
  }
  ${productBaseFragment}
`;

export async function getTemplateByID(id) {
  const { __apolloClient__: client } = window;
  if (!id || !client) return null;

  try {
    const { data } = await client.query({
      query: GET_TEMPLATE_BY_ID,
      variables: {
        id,
      },
    });

    return get(data, "getTemplateByID");
  } catch (err) {
    console.error(err?.toString());
  }
}
