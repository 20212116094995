import { gql } from "apollo-boost";
import { productBaseFragment } from "../../../fragments";
import { arrInvalid } from "../../../helper";
import { get } from "lodash";

export async function getProductBaseVariants(baseIDs) {
  const { __apolloClient__: client } = window;
  if (arrInvalid(baseIDs) || !client) return {};

  try {
    const { data } = await client.query({
      query: gql`
        query productBaseByIDs($ids: [ID!]!) {
          productBaseByIDs(ids: $ids) {
            ...ProductBasse
          }
        }
        ${productBaseFragment}
      `,
      variables: {
        ids: baseIDs,
      },
    });

    const nodes = get(data, "productBaseByIDs") || [];
    const res = {};
    for (let item of nodes) {
      if (!item || !item.id) continue;
      res[item.id] = item;
    }

    return res;
  } catch (err) {
    console.error("get variants by product base id has error", err?.toString());
  }

  return {};
}
