import { useQuery } from "@apollo/react-hooks";
import {
  Button,
  ButtonGroup,
  Card,
  Loading,
  Page,
  Tabs,
  TextField,
} from "@shopify/polaris";

import { gql } from "apollo-boost";
import React, { useCallback, useReducer, useState } from "react";
import styled from "styled-components";
import { useAppContext } from "../../../context";
import {
  getCookie,
  getParamByRole,
  reducerFn,
  setCookie,
} from "../../../helper";
import { EmptyStatePolaris } from "../../shared/EmptyStatePolaris";
import { SkeletonPagePolaris } from "../../shared/SkeletonPagePolaris";
import { TemplateProvider } from "../../templates/context";
import TableTemplateV2 from "./TableTemplateV2";

const Container = styled.div`
  margin: -1rem;
  @media (min-width: 640px) {
    margin: -1.5rem;
  }
`;

export const LIST_TEMPLATE = gql`
  query templates($filter: TemplateFilter!) {
    templates(filter: $filter) {
      total
      nodes {
        id
        name
        slug
        grouping
        isProductTemplate
        defaultMockupIds
      }
    }
  }
`;

const TemplatesV2 = ({ handleUseTemplate, allTemplate = true }) => {
  const [selected, setSelected] = useState(0);
  const [filter, setFilter] = useState({
    limit: Number(getCookie("perPageTemplates")) || 20,
    offset: 0,
    product: null,
    search: null,
  });
  const [state, setState] = useReducer(reducerFn, {
    search: "",
  });

  // Context
  const { currentUser } = useAppContext();
  const currentParam = getParamByRole(currentUser);

  const { data, loading, error, refetch } = useQuery(LIST_TEMPLATE, {
    variables: {
      filter,
    },
  });

  // Actions
  const handleTabChange = useCallback((selectedTabIndex) => {
    setSelected(selectedTabIndex);

    let filterProduct = null;
    if (selectedTabIndex === 1) {
      filterProduct = true;
    } else if (selectedTabIndex === 2) {
      filterProduct = false;
    }

    setFilter((prevState) => ({
      ...prevState,
      product: filterProduct,
      offset: 0,
    }));
  }, []);

  const handlePagination = useCallback((offset, limit) => {
    setFilter((prevState) => ({
      ...prevState,
      offset,
      limit,
    }));
    setCookie("perPageTemplates", limit, 100);
  }, []);

  const filterTimeoutRef = React.useRef(null);
  const handleSearch = useCallback((val) => {
    setState({ search: val });

    filterTimeoutRef.current && clearTimeout(filterTimeoutRef.current);
    filterTimeoutRef.current = setTimeout(() => {
      setFilter((p) => ({ ...p, search: val }));
    }, 500);
  }, []);

  // Markup
  const loadingMarkup = loading && <Loading />;
  const tabs = [
    {
      id: "all-templates",
      content: "All Templates",
      accessibilityLabel: "All Templates",
      panelID: "all-templates",
    },
    {
      id: "product-templates",
      content: "Product Templates",
      accessibilityLabel: "Product Templates",
      panelID: "product-templates",
    },
    {
      id: "campaign-templates",
      content: "Campaign Templates",
      panelID: "campaign-templates",
    },
  ];

  // Actions
  const actionMarkup = (
    <ButtonGroup>
      <Button
        children="Add Template for Product"
        url={`/${currentParam}/templates/for-product`}
      />
      <Button
        children="Add Template for Campaign"
        url={`/${currentParam}/templates/for-campaign`}
      />
    </ButtonGroup>
  );

  return (
    <Container>
      {loadingMarkup}
      <Page title="All Templates" fullWidth primaryAction={actionMarkup}>
        <Card sectioned>
          <TextField
            value={state.search}
            onChange={handleSearch}
            placeholder="Enter template name"
          />
        </Card>
        <Card>
          <TemplateProvider refetch={refetch}>
            <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
              {error && <div>Error: {error.toString()}</div>}
              {loading ? (
                <SkeletonPagePolaris />
              ) : data?.templates?.nodes?.length > 0 ? (
                <TableTemplateV2
                  allTemplate={allTemplate}
                  selectedTab={selected}
                  data={data}
                  handleUseTemplate={handleUseTemplate}
                  filter={filter}
                  setFilter={handlePagination}
                />
              ) : (
                <EmptyStatePolaris />
              )}
            </Tabs>
          </TemplateProvider>
        </Card>
      </Page>
    </Container>
  );
};

const tabs = [
  {
    id: "all-templates",
    content: "All Templates",
    accessibilityLabel: "All Templates",
    panelID: "all-templates",
  },
  {
    id: "product-templates",
    content: "Product Templates",
    accessibilityLabel: "Product Templates",
    panelID: "product-templates",
  },
  {
    id: "campaign-templates",
    content: "Campaign Templates",
    panelID: "campaign-templates",
  },
];

function TabContainer() {
  const [selected, setSelected] = useState(0);
}

export default TemplatesV2;
