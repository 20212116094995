import React, {
	useState,
	useCallback,
	useEffect,
	useRef,
} from "react";
import { Filters } from "@shopify/polaris";
import { useQuery } from "@apollo/react-hooks";
import { isEmpty } from "../../../helper";
import { FilterListRadioPolaris } from "../../filters/FilterListRadioPolaris";
import { FilterHasSearchPolaris } from "../../filters/FilterHasSearchPolaris";
import { GET_ALL_TEAM_USERS_WITH_ROOT_USER } from "../../../graphql/queries";

const STATUS_OPTIONS = [
	{ value: "All", label: "All" },
	{ value: "Pending", label: "Pending" },
	{ value: "Awaiting Approval", label: "Awaiting Approval" },
	{ value: "Paid", label: "Paid" },
	{ value: "Overdue", label: "Overdue" },
];

export const InvoicesFilterPolaris = ({ role, filter, onChange }) => {
	// State
	const [queryValue, setQueryValue] = useState(filter.search);
	const [status, setStatus] = useState({
		value: filter.status,
		label: null,
	});
	const typingTimeoutRef = useRef(null);

	// Get data
	
	useEffect(() => {
		if (filter.status) {
			let currentS = STATUS_OPTIONS.find((i) => i.value === filter.status);
			if (currentS) {
				setStatus(() => ({ value: currentS.value, label: currentS.label }));
			}
		} else {
			setStatus({
				value: null,
				label: null,
			});
		}
	}, [filter.status]);

	useEffect(() => {
		if (typingTimeoutRef.current) {
			clearTimeout(typingTimeoutRef.current);
		}
		typingTimeoutRef.current = setTimeout(() => {
			if (onChange) {
				onChange({
					search: queryValue ? queryValue.trim() : queryValue,
					status: status.value,
				});
			}
		}, 300);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [queryValue, status]);

	// Handle actions
	const handleQueryValueChange = useCallback(
		(value) => setQueryValue(value),
		[]
	);
	const handleStatusRemove = useCallback(
		() => setStatus(() => ({ value: null, label: null })),
		[]
	);
	const handleQueryValueRemove = useCallback(() => setQueryValue(null), []);

	const handleFilterClearAll = useCallback(() => {
		handleQueryValueRemove();
		handleStatusRemove();
	}, [
		handleQueryValueRemove,
		handleStatusRemove,
	]);

	const filters = [
		{
			key: "status",
			label: "Status",
			filter: (
				<FilterListRadioPolaris
					options={STATUS_OPTIONS}
					value={status.value}
					onChange={({ value, label }) => setStatus({ value, label })}
				/>
			),
		},
	];
	const appliedFilters = [];

	if (!isEmpty(status.label)) {
		const key = "status";
		appliedFilters.push({
			key,
			label: disambiguateLabel(key, status.label),
			onRemove: handleStatusRemove,
		});
	}


	return (
		<Filters
			queryPlaceholder={`Filter invoice`}
			queryValue={queryValue}
			onQueryChange={handleQueryValueChange}
			onQueryClear={handleQueryValueRemove}
			onClearAll={handleFilterClearAll}
			filters={filters}
			appliedFilters={appliedFilters}
		/>
	);
	function disambiguateLabel(key, value) {
		switch (key) {
			case "status":
				return `Status: ${value}`;
			default:
				return value;
		}
	}
};
