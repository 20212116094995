import { useMutation } from "@apollo/react-hooks";
import { Button, Modal, TextContainer } from "@shopify/polaris";
import { gql } from "apollo-boost";
import React, { useCallback, useContext, useState } from "react";
import styled from "styled-components";

import { AppContext } from "../../../context";
import { getParamByRole, handleError } from "../../../helper";
import history from "../../../history";

import useTimeout from "../../../hooks/useTimeout";
import { useToastContext } from "../../shared/ToastContext";
import { useTemplateCtx } from "../../templates/context";
import UpdateV2 from "./UpdateV2";
import { getTemplateByID } from "./utils";

const DELETE_TEMPLATE = gql`
  mutation deleteTemplate($id: ID!) {
    deleteTemplate(id: $id)
  }
`;

const Container = styled.div`
  button + button {
    margin-left: 1.25rem;
  }
`;

const ColActionsV2 = ({
  node,
  handleUseTemplate,
  allTemplate,
  selectedTab,
}) => {
  const [open, setOpen] = useState(false);
  const { id } = node || {};

  // context
  const { refetch, handleOpenExpand, templateId, handleCloseExpand } =
    useTemplateCtx();
  const { setNotify, toggleToast } = useToastContext();
  const { currentUser } = useContext(AppContext);
  const currentParam = getParamByRole(currentUser);

  const [setDelay] = useTimeout();
  const [deleteTemplate, { loading }] = useMutation(DELETE_TEMPLATE, {
    onCompleted: () => {
      handleModalChange();
      setNotify({ msg: "Template has been deleted!", err: false });

      setDelay(() => {
        refetch && refetch();
      }, 1500);
    },
    onError: (err) => {
      handleModalChange();
      setNotify({ msg: handleError(err?.toString()), err: true });
    },
  });

  const handleModalChange = useCallback(
    () => setOpen((o) => !o),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [open],
  );

  const isCurrentActive = templateId === id;
  const handleClickChange = useCallback(() => {
    isCurrentActive ? handleCloseExpand() : handleOpenExpand(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, handleOpenExpand, isCurrentActive]);

  const handleDelete = useCallback(() => {
    toggleToast && toggleToast(true);
    setNotify && setNotify({ msg: null, err: false });
    deleteTemplate({
      variables: { id: node.id },
    });
  }, [node.id, setNotify, toggleToast]);

  return (
    <Container>
      <UpdateV2 id={id} />
      <Button plain onClick={handleClickChange}>
        {isCurrentActive ? "Hide Details" : "Show Details"}
      </Button>
      <Button
        plain
        onClick={async () => {
          if (allTemplate) {
            if (
              1 === selectedTab ||
              (0 === selectedTab && node.isProductTemplate)
            ) {
              history.push(`/${currentParam}/products/add/${node.id}`);
            }
            if (
              2 === selectedTab ||
              (0 === selectedTab && !node.isProductTemplate)
            ) {
              history.push(`/${currentParam}/products/add-campaign/${node.id}`);
            }
          } else {
            const value = await getTemplateByID(node.id);
            handleUseTemplate(value);
          }
        }}
      >
        Use Template
      </Button>
      {allTemplate && (
        <Button plain onClick={handleModalChange}>
          Remove
        </Button>
      )}
      <Modal
        title="Remove template"
        open={open}
        onClose={handleModalChange}
        primaryAction={{
          content: "Remove",
          loading: loading,
          onAction: handleDelete,
        }}
      >
        <Modal.Section>
          <TextContainer>
            <p>Are you sure to delete this template?</p>
          </TextContainer>
        </Modal.Section>
      </Modal>
    </Container>
  );
};

export default ColActionsV2;
