import { CheckCircleOutlined, StarOutlined } from "@ant-design/icons";
import { Query } from "@apollo/react-components";
import {
  Avatar,
  Button,
  Card,
  Col,
  Input,
  Row,
  Select,
  Skeleton,
  Tree,
} from "antd";
import { gql } from "apollo-boost";
import _, { get } from "lodash";
import React, { Component } from "react";
import styled from "styled-components";

import { CUSTOM_FULFILLMENT } from "../../constants";
import { FULFILLMENTS_INSTALLED } from "../../graphql/queries";
import {
  checkRole,
  delay,
  getTeamID,
  handleError,
  isPME_TEAM,
} from "../../helper";

const PRODUCT_BASE_SELECTION = gql`
  query productBaseSelection($filter: SelectionFilter) {
    productBaseSelection(filter: $filter) {
      id
      name
      parentId
      productBase {
        id
        title
        slug
        baseCostDescription
        defaultContent
        defaultShortDescription
        details
        regularPrice
        salePrice
        sellerPrice
        status
        niceName
        teamId
        mockupTemplates {
          id
        }
        fulfillment {
          fulfillmentId
          productId
        }
        images {
          id
          name
          url
          thumbnailUrl
        }
      }
    }
  }
`;

class ProductbaseSelectV2 extends Component {
  state = {
    filter: {
      limit: 100,
      offset: 0,
      search: "",
    },
    search: "",
    selectedCategory: null,
    selected: [],
    fulfillments: [],
    dataSources: [],
    fulfillmentId: "",
  };

  typingTimeoutRef = React.createRef();

  componentDidMount() {
    const { value, fulfillmentId } = this.props;
    this.setState({
      selected: value ? value : [],
      fulfillmentId,
    });
  }

  getChildren = (flatData, item) => {
    let children = [];
    for (let i = 0; i < flatData.length; i++) {
      if (flatData[i].parentId === item.key) {
        const subItem = {
          ...flatData[i],
          key: flatData[i].id,
          value: flatData[i].id,
          title: flatData[i].name,
          count: flatData[i].productBase ? flatData[i].productBase.length : 0,
        };
        subItem.children = this.getChildren(flatData, subItem);
        for (let j = 0; j < subItem.children.length; j++) {
          subItem.count += subItem.children[j].count;
        }
        children.push(subItem);
      }
    }
    return children;
  };

  getTreeData = (flatData) => {
    if (!flatData || !flatData.length) {
      return [];
    }

    let categories = [];
    for (let i = 0; i < flatData.length; i++) {
      const category = flatData[i];
      if (!category.parentId) {
        let item = {
          ...flatData[i],
          title: category.name,
          key: category.id,
          value: category.id,
          count: category.productBase ? category.productBase.length : 0,
        };
        item.children = this.getChildren(flatData, item);
        for (let j = 0; j < item.children.length; j++) {
          item.count += item.children[j].count;
        }
        if (item.count > 0) {
          categories.push(item);
        }
      }
    }
    return categories;
  };

  categoryHasSearch = (category, search) => {
    if (category.title.toLowerCase().search(search) !== -1) {
      return true;
    }
    for (let i = 0; i < category.productBase.length; i++) {
      if (category.productBase[i].title.toLowerCase().search(search) !== -1) {
        return true;
      }
    }
    for (let i = 0; i < category.children.length; i++) {
      if (this.categoryHasSearch(category.children[i], search)) {
        return true;
      }
    }
    return false;
  };

  getSelectCategory = (categories, key) => {
    for (let i = 0; i < categories.length; i++) {
      if (categories[i].key === key) {
        return categories[i];
      } else {
        const cat = this.getSelectCategory(categories[i].children, key);
        if (cat) {
          return cat;
        }
      }
    }
    return null;
  };

  renderCategory = (category, level = 0) => {
    let { selected } = this.state;
    const { currentUser } = this.context;

    const { isAdministrator } = checkRole(currentUser);
    const teamId = getTeamID(currentUser);
    const isPMETeam = isPME_TEAM(currentUser);

    let baseFiltered = category.productBase || [];
    if (!isAdministrator) {
      baseFiltered = (category.productBase || []).filter((base) => {
        if (base.teamId === "" && !isPMETeam) {
          base.title = base.title + " ( Merch Bridge )";
        }
        // teamId === "" => Merch Bridge.
        return base.teamId === "" || teamId === base.teamId;
      });
    }

    return (
      <React.Fragment>
        {baseFiltered?.length > 0 ? (
          <>
            <h2>{category.name}</h2>
            <div className={"product-base-list"}>
              {baseFiltered.map((productBase, key) => (
                <div
                  onClick={() => {
                    if (this.props.multiple) {
                      const index = selected.findIndex(
                        (p) => p.id === productBase.id,
                      );
                      if (index > -1) {
                        selected.splice(index, 1);
                      } else {
                        selected.push(productBase);
                      }
                    } else {
                      selected = [productBase];
                    }

                    this.setState(
                      {
                        selected,
                      },
                      () => {
                        if (this.props.onChange) {
                          this.props.onChange(selected);
                        }
                      },
                    );
                  }}
                  className={"product-base"}
                  key={key}
                >
                  <Avatar
                    style={{ borderRadius: 0 }}
                    size={190}
                    shape="square"
                    src={_.get(productBase, "images[0].thumbnailUrl", null)}
                  />
                  <div className={"description"}>
                    <p className={"product-base-title"}>{productBase.title}</p>
                    <p className={"product-base-slug"}>{productBase.slug}</p>
                    <p className={"product-base-cost-description"}>
                      {productBase.baseCostDescription}
                    </p>
                  </div>
                  {this.state.selected.find((p) => p.id === productBase.id) &&
                    category.id && (
                      <div className={"selected-overlay"}>
                        <CheckCircleOutlined
                          style={{ fontSize: 40, color: "#207868" }}
                        />
                      </div>
                    )}
                </div>
              ))}
            </div>
          </>
        ) : null}
        {category.children.map((childCategory, key) => (
          <div className={`category level-${level + 1}`} key={key}>
            {this.renderCategory(childCategory, level + 1)}
          </div>
        ))}
      </React.Fragment>
    );
  };

  handleFulfillmentComplete = (res) => {
    if (!res) return;
    const fulfillments = getFulfillments(res);
    this.setState({ fulfillments });
  };

  handleFulfillmentChange = async (v) => {
    this.setState({ selectedCategory: null, fulfillmentId: v });

    const { onFulfillmentChange } = this.props;
    if (onFulfillmentChange) {
      await delay(() => onFulfillmentChange(v), 1000);
    }
  };

  render() {
    const { noHeader, multiple, selectedProductBases, onViewTemplates } =
      this.props;

    const { fulfillments, filter, selectedCategory, fulfillmentId } =
      this.state;
    const { typingTimeoutRef } = this;

    const baseLen = selectedProductBases?.length || 0;

    return (
      <Container>
        <Card
          bordered={true}
          title={
            <div>
              {!noHeader && (
                <h3 className={"title"}>
                  {multiple ? "Select Product bases" : "Select Product base"}
                </h3>
              )}
              <div className="flex justify-between items-center mt-4">
                <div className={"fulfillment-select"}>
                  <span style={{ fontSize: 14 }}>Fulfillment by:</span>
                  <Query
                    query={FULFILLMENTS_INSTALLED}
                    onCompleted={this.handleFulfillmentComplete}
                  >
                    {() => (
                      <Select
                        disabled={baseLen > 0}
                        onChange={this.handleFulfillmentChange}
                        style={{ minWidth: 150 }}
                        value={fulfillmentId || ""}
                      >
                        <Select.Option value={""}>MerchBridge</Select.Option>
                        {fulfillments.map((f, index) => (
                          <Select.Option key={index} value={f.id}>
                            {f.name}
                          </Select.Option>
                        ))}
                      </Select>
                    )}
                  </Query>
                </div>
                <div className={"flex"}>
                  {onViewTemplates ? (
                    <Button
                      onClick={onViewTemplates}
                      className={"mr-3"}
                      icon={<StarOutlined />}
                    >
                      All Templates
                    </Button>
                  ) : null}

                  <Input.Search
                    onSearch={(e) => {
                      this.setState({
                        filter: {
                          ...filter,
                          search: e,
                        },
                      });
                    }}
                    onChange={(e) => {
                      if (typingTimeoutRef.current) {
                        clearTimeout(typingTimeoutRef.current);
                      }
                      let { value } = e.target;
                      typingTimeoutRef.current = setTimeout(() => {
                        this.setState({
                          filter: {
                            ...filter,
                            search: value ? value : null,
                          },
                        });
                      }, 500);
                    }}
                    placeholder={"Search for product bases, category"}
                  />
                </div>
              </div>
            </div>
          }
        >
          <Query query={PRODUCT_BASE_SELECTION} variables={{ filter }}>
            {({ loading, error, data }) => {
              if (loading)
                return (
                  <div className="p-4">
                    <Skeleton active />
                  </div>
                );
              if (error)
                return <div>Error: {handleError(error.toString())}</div>;

              let dataSources = JSON.parse(
                JSON.stringify(data.productBaseSelection),
              );

              // filter fulfillment
              dataSources = dataSources.map((d) => {
                if (fulfillmentId) {
                  d.productBase = d.productBase.filter(
                    (b) =>
                      b.fulfillment &&
                      b.fulfillment.fulfillmentId === fulfillmentId,
                  );
                } else {
                  d.productBase = d.productBase.filter(
                    (b) => b.fulfillment === null,
                  );
                }
                return d;
              });

              let categories = this.getTreeData(dataSources);
              if (this.state.search) {
                categories = categories.filter((c) =>
                  this.categoryHasSearch(c, this.state.search.toLowerCase()),
                );
              }
              const list = selectedCategory
                ? [this.getSelectCategory(categories, selectedCategory)]
                : categories;

              return (
                <div className={"card-body"}>
                  <Row type="flex">
                    <Col span={5}>
                      <div className={"inner"}>
                        <Tree
                          multiple={false}
                          onSelect={(keys) => {
                            this.setState({
                              selectedCategory:
                                !keys.includes("all-product-base") &&
                                keys.length
                                  ? keys[0]
                                  : null,
                            });
                          }}
                          defaultExpandAll={true}
                          treeData={[
                            {
                              key: "all-product-base",
                              title: "All Product Bases",
                              children: [],
                            },
                            ...categories,
                          ]}
                        />
                      </div>
                    </Col>
                    <Col span={19} className={"right-col"}>
                      <div className={"inner"}>
                        {list.map((category, index) => (
                          <div className={"category level-1"} key={index}>
                            {this.renderCategory(category, 1)}
                          </div>
                        ))}
                      </div>
                    </Col>
                  </Row>
                </div>
              );
            }}
          </Query>
        </Card>
      </Container>
    );
  }
}

function getFulfillments(res) {
  const nodes = get(res, "fulfillmentsInstalled") || [];
  const arr = nodes.filter((i) => !!i.id && !!i.slug);

  for (let i = 0; i < arr.length; i++) {
    let item = arr[i];
    if (item.slug === CUSTOM_FULFILLMENT) {
      let tmp = arr[0];
      arr[0] = item;
      arr[i] = tmp;
    }
  }

  return arr;
}

export default ProductbaseSelectV2;

const Container = styled.div`
  .ant-card.ant-card-bordered .ant-card-body {
    padding: 0;
  }
  .card-body {
    .right-col {
      border-left: 1px solid #c3ced9;
    }
    .inner {
      padding: 20px;
    }
  }
  .ant-tree li .ant-tree-node-content-wrapper {
    font-weight: 700;
  }
  .ant-tree {
    .ant-tree-child-tree {
      .ant-tree-node-content-wrapper {
        font-weight: normal;
      }
    }
  }
  .product-base-list {
    display: flex;
    flex-wrap: wrap;
    .product-base {
      position: relative;
      min-height: 250px;
      width: 192px;
      border: 1px solid rgba(195, 207, 217, 1);
      box-shadow: 1px 1px 6px 0px rgba(195, 207, 217, 1);
      margin-right: 10px;
      margin-bottom: 10px;
      &:hover {
        cursor: pointer;
        &:after {
          content: " ";
          background-color: rgba(192, 192, 192, 0.3);
          position: absolute;
          height: 100%;
          width: 100%;
          top: 0;
        }
      }
      .ant-avatar-square {
        border-radius: 0;
      }
      .description {
        text-align: center;
        padding-top: 5px;
        .product-base-title {
          font-weight: 700;
          margin: auto;
        }
        .product-base-slug {
          color: #999;
          font-size: 13px;
        }
      }
      .selected-overlay {
        position: absolute;
        background: rgba(0, 0, 0, 0.4);
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .category {
    margin: 10px 0;
    h2 {
      font-weight: 700;
      margin: 10px 0;
      font-size: 16px;
    }
    .category {
      margin-bottom: 10px;
      h2 {
        font-weight: 500;
        font-size: 14px;
      }
    }
  }
  .fulfillment-select {
    max-width: 300px;
    span {
      margin-right: 5px;
      font-weight: normal;
    }
  }
`;
