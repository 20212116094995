import { gql } from "apollo-boost";
import {
  FileFragment,
  ProductHasSaleStatFragment,
  reportOrdersResultFragment,
  StoreFragment,
} from "../fragments";

export const GET_ORDER_DESIGNS = gql`
  query getOrderDesigns($id: ID!, $productBaseId: ID!) {
    getOrderDesigns(id: $id, productBaseId: $productBaseId) {
      anotherDesigns {
        id
        file {
          ...FileFragment
        }
      }
      orderDesigns {
        id
        file {
          ...FileFragment
        }
        designPosition {
          id
          name
          displayName
          description
          productBaseId
          sorting
          artworkGuidelines {
            description
            file {
              ...FileFragment
            }
          }
          image {
            ...FileFragment
          }
        }
      }
      originProduct {
        id
        designPositions {
          id
          designPosition {
            id
            sorting
            image {
              ...FileFragment
            }
          }
          file {
            ...FileFragment
          }
        }
      }
      siblingDesigns {
        id
        file {
          ...FileFragment
        }
      }
    }
  }
  ${FileFragment}
`;

export const STORE_LIST_OF_ME = gql`
  query storeListOfMe {
    storeListOfMe {
      total
      nodes {
        ...StoreFragment
      }
    }
  }
  ${StoreFragment}
`;

export const GET_MAPPING_ORDER_AGGREGATION = gql`
  query getMappingOrderAggregation($filter: MappingFilter) {
    getMappingOrderAggregation(filter: $filter) {
      ebayTotal
      amazonTotal
      etsyTotal
      onlineStoreTotal
      facebookTotal
      tiktokTotal
    }
  }
`;

export const GET_MAPPING_PRODUCT_SUGGEST = gql`
  query getMappingProductSuggest($mappingId: ID!) {
    getMappingProductSuggest(mappingId: $mappingId) {
      id
      title
      sku
      designPositions {
        id
        designPosition {
          id
          name
        }
        file {
          id
          thumbnailUrl
          url
        }
      }
      fields {
        id
        title
      }
      productBases {
        id
        title
        slug
        fulfillment {
          fulfillmentTitle
        }
        variants {
          id
          attributes {
            name
            slug
            option
          }
        }
      }
      mainImageId
      images {
        id
        file {
          id
          url
          thumbnailUrl
        }
      }
      variants {
        id
        disabled
        productBaseVariantId
        productBaseVariant {
          id
          attributes {
            name
            slug
            option
          }
          productBase {
            id
            title
          }
        }
      }
    }
  }
`;

export const REPORT_ORDERS_PENDING_OR_PENDING_DESIGN = gql`
  query reportOrdersPendingOrPendingDesign($filter: ReportOrdersFilter!) {
    reportOrdersPendingOrPendingDesign(filter: $filter) {
      ...reportOrdersResultFragment
    }
  }
  ${reportOrdersResultFragment}
`;

export const REPORT_ORDERS_ERROR = gql`
  query reportOrdersError($filter: ReportOrdersFilter!) {
    reportOrdersError(filter: $filter) {
      ...reportOrdersResultFragment
    }
  }
  ${reportOrdersResultFragment}
`;

export const REPORT_ORDERS_ON_HOLD = gql`
  query reportOrdersOnHold($filter: ReportOrdersFilter!) {
    reportOrdersOnHold(filter: $filter) {
      ...reportOrdersResultFragment
    }
  }
  ${reportOrdersResultFragment}
`;

export const REPORT_ORDERS_ERROR_FROM_MERCHIZE = gql`
  query reportOrdersErrorFromMerchize($filter: ReportOrdersFilter!) {
    reportOrdersErrorFromMerchize(filter: $filter) {
      ...reportOrdersResultFragment
    }
  }
  ${reportOrdersResultFragment}
`;

export const REPORT_ORDERS_ERROR_WITHOUT_FF_OR_SUPPLIER = gql`
  query reportOrdersErrorWithoutFFOrSupplier($filter: ReportOrdersFilter!) {
    reportOrdersErrorWithoutFFOrSupplier(filter: $filter) {
      ...reportOrdersResultFragment
    }
  }
  ${reportOrdersResultFragment}
`;

export const PLATFORM_SALE_STATS = gql`
  query platformSaleStats($filter: PlatformSaleStatsFilter!) {
    platformSaleStats(filter: $filter) {
      total
      totalOrder
      nodes {
        ...ProductHasSaleStatFragment
      }
    }
  }
  ${ProductHasSaleStatFragment}
`;

export const REPORT_TOTAL_COST_BY_FULFILLMENT = gql`
  query reportTotalCostByFulfillment(
    $filter: ReportTotalCostByFulfillmentFilter!
  ) {
    reportTotalCostByFulfillment(filter: $filter) {
      totalCost
      totalAllCost
      totalOrders
      totalAllOrders
      total
      nodes {
        totalCost
        totalOrders
        orderCreatedAtStr
      }
    }
  }
`;

export const GET_ORDER_EXPORT_CONFIRM_SHIPMENT_TO_DAY_V2 = gql`
  query getOrderExportConfirmShipmentToDayV2(
    $limit: Int
    $paged: Int
    $storeId: ID
  ) {
    getOrderExportConfirmShipmentToDayV2(
      limit: $limit
      paged: $paged
      storeId: $storeId
    ) {
      total
      nodes {
        status
        order {
          id
          originId
          store {
            id
            title
          }
        }
      }
    }
  }
`;

export const TOTAL_MAPPING_ORDER = gql`
  query totalMappingOrder($storeId: ID) {
    totalMappingOrder(storeId: $storeId)
  }
`;


export const GET_TASK_ASSIGNEE_BY_ORDER_IDS = gql`
  query getTaskAssigneeByOrderIDs($orderIds: [ID!]!) {
    getTaskAssigneeByOrderIDs(orderIds: $orderIds) {
      orderID
      assignee {
        firstName
        lastName
      }
    }
  }
`;

export const GET_TIKROK_LABEL_FILES = gql`
  query getTiktokLabelFiles($orderIDs: [ID!]!) {
    getTiktokLabelFiles(orderIDs: $orderIDs)
  }
`;
