import { Button, Card, Modal } from "@shopify/polaris";
import { findIndex, get } from "lodash";
import React, { Fragment, useCallback, useEffect, useRef } from "react";
import { elementContains } from "../../../../helper";
import useToggle from "../../../../hooks/useToggle";
import { TemplatesPolaris } from "../../../product/templates/TemplatesPolaris";
import { useCreateIdea } from "../context/createIdea";
import TemplatesV2 from "../../../product/templates/TemplatesV2";

export function TemplatesSection({
    fields,
    products: productsRoot,
    setActiveKey,
}) {
    // State
    const [open, toggleOpen] = useToggle(false);
    const timeoutRef = useRef(null);

    const { addProduct, toggleForceUpdate } = useCreateIdea();

    // Actions
    const toggleUseTemplate = useCallback(() => {
        toggleOpen();
    }, [toggleOpen]);

    const handleUseTemplate = useCallback(
        (template) => {
            const { data, productBases: basesOuter } = template || {};

            // Product fields
            let title = "",
                shortTitle = "",
                amzTitle = "",
                description = "",
                tagIds = [],
                collectionIds = [],
                fields = data?.fields || [];

            // Product template
            const products = data?.products || [];
            const product = products?.length > 0 ? products[0] : null;
            if (product != null) {
                ({
                    description,
                    tags: tagIds,
                    collections: collectionIds,
                } = product);
            }

            fields = (fields || []).map((f) => {
                const { __typename, sorting, ...rest } = f || {};
                return rest;
            });

            let mapProductByBaseId = {};
            if (products?.length > 0) {
                for (let i = 0; i < products.length; i++) {
                    const p = products[i];
                    if (p) {
                        mapProductByBaseId[p.productbaseId] = p;
                    }
                }
            }

            let productBases =
                data?.productBases?.length > 0 ? data.productBases : [];
            const isPersonalized = !!data?.personalized;
            productBases = productBases.map((b) => {
                const mapBase = (basesOuter || []).find(
                    (base) => base.id === b.id
                );
                let mockupTemplates = b.mockupTemplates;
                if (mapBase) {
                    ({ mockupTemplates } = mapBase);
                }

                return {
                    ...b,
                    mockupTemplates,
                };
            });

            const newProduct = {
                title,
                shortTitle,
                amzTitle,
                description,
                collectionIds,
                tagIds,
                isPersonalized,
                fields,
                isCampaign: !!(productBases?.length > 1),
                bases: productBases.map((b) => {
                    let product = mapProductByBaseId[b.id];

                    const item = {
                        ...b,
                        variants: b.variants.map((variant, index) => {
                            let currentBaseVarID = variant.id;
                            let i = index;
                            i = findIndex(product.variants, {
                                productBaseVariantID: currentBaseVarID,
                            });

                            let v = {
                                productBaseVariant: variant,
                                productBaseVariantId: variant.id,
                                image: null,
                                disabled: get(variant, "disabled", true),
                            };
                            if (template) {
                                const pv = get(product, `variants[${i}]`);
                                v.disabled = get(pv, "disabled", false);
                            }
                            return v;
                        }),
                    };
                    return item;
                }),
                medias: [],
                psdFiles: [],
                relatedProductIds: [],
                ideaDescription: "",
                psd: "",
                isPriority: !!fields.priority,
            };

            addProduct({ ...newProduct });

            timeoutRef.current = setTimeout(() => {
                toggleForceUpdate(true);
            }, 1500);

            // Set active key
            const last = Math.max(0, productsRoot.length - 1);
            setActiveKey(`product-${last}`);
            toggleOpen(false);
        },
        [
            fields,
            addProduct,
            toggleForceUpdate,
            setActiveKey,
            productsRoot,
            toggleOpen,
        ]
    );

    // Markup
    useEffect(() => {
        let domCD = document.querySelector("#use-template-wrap");
        let domParents = document.querySelectorAll(
            ".Polaris-Modal-Dialog__Modal"
        );
        for (let domParent of domParents) {
            if (elementContains(domParent, domCD)) {
                domParent.setAttribute(
                    "style",
                    "max-width: calc(100% - 5rem); height: calc(100vh)"
                );
            }
        }
    });

    useEffect(
        () => () => {
            if (timeoutRef.current) clearTimeout(timeoutRef.current);
        },
        []
    );

    return (
        <Fragment>
            <Card.Section>
                <Button children="Use Templates" onClick={toggleUseTemplate} />
            </Card.Section>
            <Modal
                title="Use Templates"
                sectioned
                open={open}
                onClose={toggleUseTemplate}
                large
            >
                <div id="use-template-wrap">
                    {/* <TemplatesPolaris
                        handleUseTemplate={handleUseTemplate}
                        allTemplate={false}
                    /> */}

                      <TemplatesV2
                        handleUseTemplate={handleUseTemplate}
                        allTemplate={false}
                    />
                    
                </div>
            </Modal>
        </Fragment>
    );
}
