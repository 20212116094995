export const ROLE = {
  ADMIN: "Admin",
  SUPPLIER: "Supplier",
  SELLER: "Seller",
};

export const ORDER_FILTER_SELLER = [
  { name: "All", key: null },
  { name: "Pending Design", key: "Pending Design" },
  { name: "Pending", key: "Pending" },
  { name: "On Hold", key: "On Hold" },
  { name: "Supplier Processing", key: "Processing" },
  { name: "In Production", key: "In Production" },
  { name: "Fulfilled", key: "Fulfilled" },
  { name: "Cancelled", key: "Cancelled" },
  { name: "Design Rejected", key: "Design Rejected" },
  { name: "Error", key: "Error" },
  { name: "Refunded", key: "Refunded" },
];

export const ORDER_FILTER_SUPPLIER = [
  { name: "All", key: null },
  { name: "Pending Payment", key: "Pending Payment" },
  { name: "Ready To Print", key: "Ready to Print" },
  { name: "Printing", key: "Printing" },
  { name: "Fulfilled", key: "Fulfilled" },
  { name: "Refunded", key: "Refunded" },
];

export const ORDER_FILTER_SELLER_SUPPLIER = [
  { name: "All", key: null },
  { name: "Processing", key: "Processing" },
  { name: "Printing", key: "Printing" },
  { name: "Fulfilled", key: "Fulfilled" },
  { name: "Refunded", key: "Refunded" },
];

export const CLAIM_FILTER_SUPPLIER = [
  { name: "All", key: null },
  { name: "Need Resend", key: "Need Resend" },
  { name: "Need Refund", key: "Need Refund" },
  { name: "Resent", key: "Resent" },
  { name: "Refunded", key: "Refunded" },
];

export const CLAIM_FILTER = [
  { name: "All", key: null },
  { name: "In Review", key: "In Review" },
  { name: "Need more evidence", key: "Need more evidence" },
  { name: "Approved", key: "Approved" },
  { name: "Resent", key: "Resent" },
  { name: "Refunded", key: "Refunded" },
  { name: "Rejected", key: "Rejected" },
  { name: "Cancelled", key: "Cancelled" },
];

export const CLAIM_TYPE = {
  NeedResend: "Need Resend",
  NeedRefund: "Need Refund",
};

export const TRACKING_STATUS = [
  { key: "notfound", name: "No Info" },
  {
    key: "inforeceived",
    name: "Info Received",
    color: "#ccced0",
    colorHover: "#babbbc",
  },
  {
    key: "transit",
    name: "In Transit",
    badgeStatus: "info",
    color: "#b4e1fa",
    colorHover: "#9fcde6",
  },
  {
    key: "delivered",
    name: "Delivered",
    badgeStatus: "success",
    color: "#bbe5b3",
    colorHover: "#9fbf99",
  },
  {
    key: "undelivered",
    name: "Undelivered",
    badgeStatus: "critical",
    color: "#fead9a",
    colorHover: "#f19b87",
  },
  {
    key: "pickup",
    name: "Pick up",
    badgeStatus: "info",
    color: "#b4e1fa",
    colorHover: "#9fcde6",
  },
  {
    key: "exception",
    name: "Exception",
    badgeStatus: "critical",
    color: "#fead9a",
    colorHover: "#f19b87",
  },
  {
    key: "expired",
    name: "Expired",
    badgeStatus: "warning",
    color: "#ffc58b",
    colorHover: "#efb275",
  },
  {
    key: "pending",
    name: "Pending",
    badgeStatus: "attention",
    color: "#ffea8a",
    colorHover: "#f9e27a",
  },
];

export const TRACKING_STATUS_DAYS = {
  "10daysnotfound": "10 Days no info",
  "12daysnotfound": "12 Days no info",
};

export const CLAIM_STATUS = {
  Resent: "Resent",
  Refunded: "Refunded",
};

export const PRODUCTS_COUNT = [
  { name: "Pending Design", key: "pending_design" },
  { name: "Pending", key: "pending" },
  { name: "On Hold", key: "on_hold" },
  { name: "In Production", key: "in_production" },
  { name: "Fulfilled", key: "fulfilled" },
  { name: "Cancelled", key: "cancelled" },
  { name: "Error", key: "error" },
  { name: "Refunded", key: "refunded" },
  { name: "Pending Payment", key: "pending_payment" },
  { name: "Ready To Print", key: "ready_to_print" },
  { name: "Printing", key: "printing" },
];

export const ORDER_STATUS_COLORS = [
  { name: "Pending Design", background: "#f6c343", color: "#283e59" },
  { name: "Pending", background: "#2c7be5", color: "#fff" },
  { name: "On Hold", background: "#d7eff6", color: "#39afd1" },
  { name: "In Production", background: "#00d97e", color: "#fff" },
  { name: "Fulfilled", background: "#d5e5fa", color: "#2c7be5" },
  { name: "Cancelled", background: "#e63757", color: "#fff" },
  { name: "Error", background: "#fad7dd", color: "#e63757" },
  { name: "Design Rejected", background: "#fad7dd", color: "#e63757" },
  { name: "Refunded", background: "#d0d4d9", color: "#12263f" },
  { name: "Pending Payment", background: "#6e84a3", color: "#fff" },
  { name: "Ready to Print", background: "#00d97e", color: "#fff" },
  { name: "Printing", background: "#ccf7e5", color: "#00d97e" },
];

export const TASK_STATUS_COLORS = [
  { name: "Pending", background: "#2c7be5", color: "#fff" },
  { name: "Doing", background: "#00d97e", color: "#fff" },
  { name: "Need Review", background: "#d7eff6", color: "#39afd1" },
  { name: "Rejected", background: "#fad7dd", color: "#e63757" },
  { name: "Done", background: "#d5e5fa", color: "#2c7be5" },
  { name: "Cancel", background: "#e63757", color: "#fff" },
  { name: "Denied", background: "#6e84a3", color: "#fff" },
  { name: "In Review", background: "#d7eff6", color: "#39afd1" },
  { name: "Expired", background: "#fad7dd", color: "#e63757" },
];

export const SCREEN_OPTIONS = [
  "originId",
  "product",
  "productBases",
  "productVariant",
  "qty",
  "cost",
  "store",
  "supplier",
  "carrier",
  "createdAt",
  "tracking",
  "status",
];

export const fieldsProductBase = [
  "baseCostDescription",
  "carriers",
  "categories",
  "defaultContent",
  "defaultShortDescription",
  "designPositions",
  "details",
  "fulfillment",
  "hasVariant",
  "images",
  "regularPrice",
  "salePrice",
  "sellerPrice",
  "slug",
  "suppliers",
  "title",
  "attributes",
  "variants",
  "carrierPricing",
  "supplierPricing",
];

export const fieldsProduct = [
  "title",
  "description",
  // "shortDescription",
  "collections",
  "tags",
  "productBaseId",
  "variants",
  "regularPrice",
  "salePrice",
  "personalized",
  "designPositions",
  "mockup",
  "pushToStores",
  "fields",
  "shortTitle",
  "amzTitle",
];

export const fieldsCampaign = [
  // "products",
  "personalized",
  "fields",
  "pushToStores",
  "title",
  "description",
  // "shortDescription",
  "collections",
  "tags",
  "psd",
  "psdFiles",
  "shortTitle",
  "amzTitle",
];

export const fieldsCampaignProductBaseItem = [
  "collections",
  "description",
  "shortDescription",
  "tags",
  "title",
  "variants",
  "regularPrice",
  "salePrice",
];

export const COLOR_CODES = [
  {
    name: "White",
    value: "#FFFFFF",
  },
  {
    name: "Sliver",
    value: "#C0C0C0",
  },
  {
    name: "Gray",
    value: "#808080",
  },
  {
    name: "Black",
    value: "#000000",
  },
  {
    name: "Red",
    value: "#FF0000",
  },
  {
    name: "Maroon",
    value: "#800000",
  },
  {
    name: "Yellow",
    value: "#FFFF00",
  },
  {
    name: "Olive",
    value: "#808000",
  },
  {
    name: "Lime",
    value: "#00FF00",
  },
  {
    name: "Green",
    value: "#008000",
  },
  {
    name: "Aqua",
    value: "#00FFFF",
  },
  {
    name: "Teal",
    value: "#008080",
  },
  {
    name: "Blue",
    value: "#0000FF",
  },
  {
    name: "Navy",
    value: "#000080",
  },
  {
    name: "Fuchsia",
    value: "#FF00FF",
  },
  {
    name: "Purple",
    value: "#800080",
  },
];

export const STATUS_BADGE_MOCKUP_2D = {
  Pending: "info",
  Doing: "new",
  "Need Review": "attention",
  Done: "success",
  Error: "critical",
  Rejected: "warning",
};

export const IFRAME_MESSAGE_TYPE = {
  Regenerate: "Regenerate",
  Subscriptions: "Subscriptions",
  SendReview: "SendReview",
  CreateCustomMockup: "CreateCustomMockup",
  CreateMockupForSubmission: "CreateMockupForSubmission",
  OpenSuccess: "OpenSuccess",
  ChangedLayer: "ChangedLayer",
  DeleteMockups: "DeleteMockups",
  ChooseOtherTemplate: "ChooseOtherTemplate",
  BulkDeleteMockups: "BulkDeleteMockups",
  GetMainMockupId: "GetMainMockupId",
};

export const FULFILLMENT_SS_OPTIONS = {
  None: "None",
  All: "All",
  Ground: "Ground",
  "2 Day": "2 Day",
  "Standard Overnight": "Standard Overnight",
  "Fastship 2days": "Fastship 2days",
  express: "express",
  "US-FC": "US-FC",
  "US-PP": "US-PP",
  "US-PS": "US-PS",
  "US-PM": "US-PM",
  "US-FCI": "US-FCI",
  "US-PMI": "US-PMI",
  "UPS-1DA": "UPS-1DA",
  "UPS-1DP": "UPS-1DP",
  "UPS-2DA": "UPS-2DA",
  "UPS-3DS": "UPS-3DS",
  "UPS-GND": "UPS-GND",
  "FDX-FO": "FDX-FO",
  "FDX-PO": "FDX-PO",
  "FDX-SO": "FDX-SO",
  "FDX-2DA": "FDX-2DA",
  "FDX-2D": "FDX-2D",
  "FDX-ES": "FDX-ES",
  "FDX-GND": "FDX-GND",
  "DHL-SM": "DHL-SM",
  "DHL-SME": "DHL-SME",
  "DHL-GM": "DHL-GM",
  "DHL-GME": "DHL-GME",
};

export const PRIVATE_FEATURES = {
  "fb-marketplace": "Facebook Marketplace",
  upcs: "UPCs Management",
  "products-ignored": "Products Ignored",
  "re-mockups": "Re-Mockup Conditions",
  "re-mockups-tasks": "Re-Mockup Tasks",
  "product-duplicate": "Product by Title",
  "product-from-print-file": "Product from Print File",
  "switch-store-for-order": "Switch Store for Order",
  "facebook-mk": "Facebook MK",
  reports: "Marketplace",
  "product-crawlers": "Product Crawlers",
  "product-assortment": "Product Assortment",
  "complex-assortment": "Complex Assortment",
  "payment-accounts": "Payment Accounts",
};

export const PRINTIFY_SLUG = "printify";
export const CUSTOM_FULFILLMENT = "custom-fulfillment";
export const PRINTWAY_SLUG = "printway";
export const SUPPER_ADMIN = ["the-supper-admin@merchbridge.com"];
export const MERCHIZE_SLUG = "merchize";

export const FULFILLMENTS_SLUG = {
  Customcat: "customcat",
  Gearment: "gearment",
  Dreamship: "dreamship",
  Scalablepress: "scalablepress",
  Printify: "printify",
  Printway: "printway",
  Merchize: "merchize",
  Teezily: "teezily",
  BurgerPrints: "burgerprints",
  MangoTeePrints: "mangoteeprints",
  Fivetify: "fivetify",
  Flashship: "flashship",
};

export const TEEZILY_DESIGN_SPECIFICATION = {
  TSRN_U: ["front", "back"],
  TSRN_W: ["front", "back"],
  TSVN_U: ["front", "back"],
  TSVN_W: ["front", "back"],
  TSLS_U: ["front", "back"],
  SWTR_U: ["front", "back"],
  HOOD_U: ["front", "back"],
  TSRN_K: ["front", "back"],
  HOOD_K: ["front", "back"],
  TKTP_U: ["front", "back"],
  TKTP_W: ["front", "back"],
  BODY_B: ["front", "back"],
  BODYLS_B: ["front", "back"],
  OTSRN_U: ["front", "back"],
  OTSRN_W: ["front", "back"],
  OTSVN_U: ["front", "back"],
  OTSVN_W: ["front", "back"],
  OTSLS_U: ["front", "back"],
  OSWTR_U: ["front", "back"],
  OHOOD_U: ["front", "back"],
  PTSRN_U: ["front", "back"],
  PSWTR_U: ["front", "back"],
  PHOOD_U: ["front", "back"],
  TOTE_U: ["front", "back"],
  MUG_U: ["front"],
  BMUG_U: ["front"],
  BTTMUG_U: ["front"],
  LMUG_U: ["front"],
  RMUG_U: ["front"],
  TTMUG_U: ["front"],
  "DMAT3-2_U": ["front"],
  FLEEB_U: ["front"],
  FMASK_U: ["front"],
  IP5C_U: ["front"],
  IP5SC_U: ["front"],
  IP6C_U: ["front"],
  IP6PC_U: ["front"],
  SGS5C_U: ["front"],
  SGS6C_U: ["front"],
  "MIPICA2-1_U": ["front"],
  "SAPICA1-1_U": ["front"],
  NECKL_R: ["front"],
  NECKL_S: ["front"],
  ORCE_H: ["front"],
  ORCE_R: ["front"],
};

export const ICOM_TEAM_ID = "CeaM3o0Yq0";
export const SETTING_VARIABLES = {
  BG: "__background_image",
  // verify shipping cost
  VSC: "__verify_shipping_cost",
  // set on-hold( order status ) time
  SOHT: "__set_on_hold_time",
  // config mail notification
  MN: "__config_mail_notification",

  // fulfillment config
  FC: "enableStoreFulfillment",
};

export const MAPPING_EVENT_NAMES = [
  "mapping_item_is_created",
  "mapping_item_is_deleted",
  "mapping_item_is_mapped",
];

export const PRIVILEGES_KEYS = {
  VWTR: "view_whole_team_report"
}

export const WAREHOUSE_VALUES = ["NJ", "CA", "TX1", "VN"]

export const TIKTOK_ACTIVITY_TYPE = {
  FLASHSALE: "FLASHSALE",
  FIXED_PRICE: "FIXED PRICE",
  DIRECT_DISCOUNT: "DIRECT DISCOUNT",
}

export const TIKTOK_PRODUCT_LEVEL = {
  // PRODUCT: "PRODUCT",
  VARIATION: "VARIATION",
}