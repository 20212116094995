import { useQuery } from "@apollo/react-hooks";
import { Card, Loading, Tabs } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { isEqual } from "lodash";
import React, { useRef, useEffect, useMemo, useState } from "react";
import {
	convertObjectToParams,
	convertStringToObject,
	convertToOffset,
	convertToPaged,
	getCookie,
	handleError,
	matchPathName,
	removeFieldWithoutFilter,
	setCookie,
} from "../../../helper";
import history from "../../../history";
import { COMMON_FILTER, USER_ROLE } from "../../../variable";
import { EmptyStatePolaris } from "../../shared/EmptyStatePolaris";
import { SkeletonPagePolaris } from "../../shared/SkeletonPagePolaris";
import { DataTableInvoicesPolaris } from "./DataTableInvoicesPolaris";
import { InvoicesFilterPolaris } from "./InvoicesFilterPolaris";

const QUERY_TRANSACTIONS = gql`
  query invoices($filter: InvoiceFilter!) {
    invoices(filter: $filter) {
      total
      nodes {
        id
        createdAt
        name
        totalPrice
		status
		team {
			id
			name
		}
      }
    }
  }
`;

export const ACTIONS = [
	{ key: "Order", name: "Paid For Item" },
	{ key: "Refund", name: "Refund" },
];

const FIELD_FILTER = [
	...COMMON_FILTER,
	"status",
];

export const InvoicesPolaris = ({ path }) => {
	const isMatchPathName = useMemo(() => matchPathName(path), [path]);
	const initFilter = useMemo(() => {
		let initialFilter = {
			// ...convertStringToObject(history.location.search),
		};
		if (initialFilter) {
			let limit = initialFilter.limit;
			limit = Number(limit);
			if (!limit) {
				limit = 20;
			}
			initialFilter.limit = limit;

			if (initialFilter.paged) {
				initialFilter.offset = convertToOffset(
					initialFilter.limit,
					Math.round(initialFilter.paged),
				);
			}
			initialFilter = removeFieldWithoutFilter(initialFilter, FIELD_FILTER);
		}
		return initialFilter;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isMatchPathName]);
	// State

	const [filter, setFilter] = useState({
		limit: Number(getCookie("perPageInvoices")) || 20,
		offset: 0,
		search: null,
		status: null,
		...initFilter,
	});

	// query
	const { data, loading, error } = useQuery(QUERY_TRANSACTIONS, {
		variables: {
			filter,
		},
	});

	// Handle action
	useEffect(() => {
		return;
		let { offset, limit, ...rest } = filter;

		let params = null;
		let paged = convertToPaged(limit, offset);
		params = convertObjectToParams({
			limit,
			paged,
			...rest,
		});
		// history.push(`${history.location.pathname}?${params}`);
	}, [filter]);

	// Markup
	const loadingMarkup = loading && <Loading />;

	const isFilterMounted = useRef(true);
	useEffect(() => {
		isFilterMounted.current = true;
		return () => {
			isFilterMounted.current = false;
		};
	}, []);

	return (
		<React.Fragment>
			{loadingMarkup}
			<Card sectioned>
				<InvoicesFilterPolaris
					filter={filter}
					onChange={({ search, status, teamID }) => {
						if (isFilterMounted.current) {
							setFilter((prev) => {
								if ( !isEqual(prev.search, search) || !isEqual(prev.status, status) || !isEqual(prev.team_id, teamID) ) {
									return {
										...prev,
										search,
										status,
										teamID,
										offset: 0,
									};
								}
								return prev;
							});
						}
					}}
				/>
			</Card>
			<Card>
				{error && <div>Error: {handleError(error.toString())}</div>}
				<>
					{loading ? (
						<SkeletonPagePolaris />
					) : data?.invoices?.nodes?.length > 0 ? (
						<DataTableInvoicesPolaris
							data={data}
							filter={filter}
							setFilter={(offset, limit) => {
								setFilter((prev) => {
									if (
										!isEqual(prev.offset, offset) ||
										!isEqual(prev.limit, limit)
									) {
										return {
											...prev,
											offset,
											limit,
										};
									}
									return prev;
								});
								setCookie("perPageInvoices", limit, 100);
							}}
						/>
					) : (
						<EmptyStatePolaris />
					)}
				</>
			</Card>
		</React.Fragment>
	);
};
