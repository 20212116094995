import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from "react";
import { gql } from "apollo-boost";
import { useLazyQuery } from "@apollo/react-hooks";
import { get } from "lodash";

import { reducerFn } from "../../helper";
import { productBaseFragment } from "../../fragments";

const GET_TEMPLATE_BY_ID = gql`
  query getTemplateByID($id: ID!) {
    getTemplateByID(id: $id) {
      id
      name
      slug
      grouping
      isProductTemplate
      defaultMockupIds
      productBases {
        id
        mockupTemplates {
          id
          originTemplateID
          isDefault
        }
      }
      data {
        personalized
        productBases {
          ...ProductBasse
        }
        ProductBaseIds
        fields {
          name
          title
          type
          extraFee
          configure
          sorting
        }
        products {
          title
          description
          productbaseId
          shortDescription
          tagIds
          tags {
            id
            name
          }
          variants {
            salePrice
            regularPrice
            productBaseVariantID
            disabled
          }
          collectionIds
          collections {
            id
            name
          }
        }
      }
    }
  }
  ${productBaseFragment}
`;

const TemplateContext = createContext(null);

const mapData = new Map();
export function TemplateProvider({ children, ...props }) {
  const [state, setState] = useReducer(reducerFn, {
    active: false,
    index: -1,

    templateId: null,
    template: null,
    loading: false,
  });

  const [getTemplate, { loading, data }] = useLazyQuery(GET_TEMPLATE_BY_ID);

  useEffect(() => {
    if (data) {
      const template = get(data, "getTemplateByID");
      setState({ loading: false, template });
      if (template?.id) {
        mapData.set(template.id, template);
      }
    }
  }, [loading]);

  const handleOpenExpand = useCallback(
    (id) => {
      setState({ active: true, templateId: id });

      const cache = mapData.get(id);
      const newState = { active: true, templateId: id, loading: true }
      if (!!cache) {
        newState.template = cache;
        newState.loading = false;
      } else {
        getTemplate({
          variables: {
            id,
          },
        });
      }

      setState(newState)
    },
    [getTemplate],
  );

  const handleCloseExpand = useCallback(() => {
    setState({
      active: false,
      loading: false,
      templateId: null,
      template: null,
    });
  }, []);

  return (
    <TemplateContext.Provider
      value={{ ...state, ...props, handleOpenExpand, handleCloseExpand }}
    >
      {children}
    </TemplateContext.Provider>
  );
}

export function useTemplateCtx() {
  return useContext(TemplateContext);
}
