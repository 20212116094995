import React from "react";
import { Modal } from "@shopify/polaris";

import { AssignTemplatePolaris } from "./AssignTemplatePolaris";
// import SuspenseComp from "../../shared/SuspenseComp";
// import SkeletonPagePolaris from "../../shared/SkeletonPagePolaris";

// const AssignTemplatePolarisV2 = React.lazy(() =>
//   import("./AssignTemplatePolarisV2"),
// );

export const AssignTemplateButtonPolaris = (props) => {
  const { open, toggleShowModal } = props;

  return (
    <Modal
      open={open}
      onClose={toggleShowModal}
      fluidContent
      sectioned
      title="Assign product use template"
      large
    >
      <AssignTemplatePolaris {...props} /> 
      {/* <SuspenseComp fallback={<SkeletonPagePolaris />}>
        <AssignTemplatePolarisV2 {...props} />
      </SuspenseComp> */}
    </Modal>
  );
};
