import React from "react";
import { useLazyQuery } from "@apollo/react-hooks";
import get from "lodash/get";

import {
  GET_TIKROK_LABEL_FILES,
  GET_TIKTOK_PRODUCT_VIA_ORDER,
} from "../../../graphql/queries";
import { arrInvalid, checkPlatform, checkRole } from "../../../helper";
import { useAppContext } from "../../../context";

const LabelLinkContext = React.createContext(null);
export function TiktokInfoProvider({ data, children }) {
  const [fileUrls, setFileUrls] = React.useState({});
  const [productInfo, setProductInfo] = React.useState({});

  const { currentUser } = useAppContext();
  const { isAdministrator } = checkRole(currentUser);
  const team = get(currentUser, "teamUser");
  const hasTeam = !!team;

  const [getFiles] = useLazyQuery(GET_TIKROK_LABEL_FILES, {
    onCompleted: (res) => {
      const fileUrls = fileUrlByOriginID(res);
      if (Object.keys(fileUrls).length > 0) {
        setFileUrls(fileUrls);
      }
    },
  });

  const [getProductInfo] = useLazyQuery(GET_TIKTOK_PRODUCT_VIA_ORDER, {
    onCompleted: (res) => {
      const productInfo = mapProductByOrder(res);
      if (Object.keys(productInfo).length > 0) {
        setProductInfo(productInfo);
      }
    },
  });

  const ids = getOrderIDs(data);
  React.useEffect(() => {
    if (arrInvalid(ids) || (!isAdministrator && !hasTeam)) return;
    (async () => {
      await Promise.all([
        getFiles({
          variables: {
            orderIDs: ids,
          },
        }),
        getProductInfo({
          variables: {
            orderIDs: ids,
          },
        }),
      ]);
    })();
  }, [JSON.stringify(ids), isAdministrator, hasTeam]);

  return (
    <LabelLinkContext.Provider value={{ fileUrls, productInfo }}>
      {children}
    </LabelLinkContext.Provider>
  );
}

function getOrderIDs(nodes) {
  if (arrInvalid(nodes)) return [];

  const arr = [];
  for (let node of nodes) {
    const { store, id } = node || {};
    if (!node || !store) continue;

    const { platform } = store;
    if (!checkPlatform(platform)) continue;

    arr.push(id);
  }

  return arr;
}

function fileUrlByOriginID(res) {
  return get(res, "getTiktokLabelFiles");
}

function mapProductByOrder(res) {
  const nodes = get(res, "getTiktokProductViaOrder") || [];

  if (nodes.length === 0) return {};
  const result = {};
  for (let node of nodes) {
    if (!node || !node.orderID) continue;
    result[node.orderID] = node;
  }

  return result;
}

export const useTiktokInfoCtx = () => React.useContext(LabelLinkContext);
