import { Badge, DataTable } from "@shopify/polaris";
import _ from "lodash";
import React from "react";
import styled from "styled-components";
import { PaginationPolaris } from "../../shared/PaginationPolaris";
import ColActionsV2 from "./ColActionsV2";
import ViewTemplateActive from "./ViewTemplateActive";

export default function TableTemplateV2({
  data,
  handleUseTemplate,
  filter,
  setFilter,
  allTemplate,
  selectedTab,
}) {
  let newRows = [];
  if (data) {
    data.templates.nodes.forEach((temp, idx) => {
      newRows.push([
        <div className="flex flex-col">
          <span>
            <strong>{`#${++idx} `}</strong>
            {temp.name}
            {"  "}
            {2 === selectedTab && temp.grouping ? (
              <Badge status="success">Grouping</Badge>
            ) : (
              ""
            )}
          </span>
          <span>{temp.slug}</span>
          <ViewTemplateActive node={temp} />
        </div>,
        <ColActionsV2
          node={temp}
          idx={idx}
          handleUseTemplate={handleUseTemplate}
          filter={filter}
          allTemplate={allTemplate}
          selectedTab={selectedTab}
        />,
      ]);
    });
  }

  let { limit, offset } = filter;
  const total = _.get(data, "templates.total", 0);
  const totalPage = Math.ceil(total / limit);
  const page = offset / limit + 1;
  const aggregation = {
    page,
    totalPage,
    offset,
    limit,
    onChange: setFilter,
    total,
  };

  return (
    <Container>
      <DataTable
        columnContentTypes={["text", "numeric"]}
        headings={["Name", "Actions"]}
        rows={newRows}
        verticalAlign={"top"}
        footerContent={
          <PaginationPolaris aggregation={aggregation} showTotal />
        }
      />
    </Container>
  );
}

const Container = styled.div`
  .bases-wrap {
    display: flex;
    flex-direction: row;
    margin: 2rem;
  }
  .img-wrap {
    margin-right: 3rem;
  }
  .attribute-title,
  .variant-total {
    margin-top: 1rem;
  }
  .Polaris-Tag + .Polaris-Tag {
    margin-left: 1rem;
  }
  .attr-option-val {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: "tnum";
    font-feature-settings: "tnum", "tnum";
    display: inline-block;
    height: auto;
    margin-right: 8px;
    margin-top: 8px;
    padding: 0 7px;
    font-size: 12px;
    line-height: 20px;
    white-space: nowrap;
    background: #fafafa;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    cursor: default;
    opacity: 1;
    -webkit-transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
    transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  }
  .attr-option-val.pm-tag-enabled {
    background-color: rgb(24, 144, 255);
    border-color: transparent;
    color: #fff;
  }
  .Polaris-DataTable__Footer {
    overflow-x: scroll;
  }
  .Polaris-DataTable__Navigation {
    display: none;
  }
`;
