import React, { useState, useEffect, useCallback } from "react";
import { DataTable, Badge, Link, Popover, ActionList, Button } from "@shopify/polaris";
import { get } from "lodash";
import moment from "moment";
import styled from "styled-components";
import { PaginationPolaris } from "../../shared/PaginationPolaris";
import { useHistory } from "react-router-dom";

const Container = styled.div`
  .Polaris-DataTable__Footer {
    overflow-x: scroll;
  }
  .note-wrap {
    width: calc(20rem - 3.2rem);
    white-space: normal;
    word-break: break-word;
  }
`;

const STATUS_BADGE = {
	Pending: "info",
	Paid: "success",
	Overdue: "critical",
	Frozen: "critical",
	'Awaiting Approval': "warning",
};

export const DataTableInvoicesPolaris = ({
	data,
	filter,
	setFilter,
}) => {
	const history = useHistory();
	const routePrefix = `/seller/billings/invoice`;
	// State
	const [rows, setRows] = useState([]);
	const [columnContentTypes, setColumnContentTypes] = useState([]);
	const [headings, setHeadings] = useState([]);

	const [activeRow, setActiveRow] = useState(null);
	const renderActions = useCallback((invoice) => (
		<Popover
			active={activeRow === invoice.id}
			activator={
				<Button disclosure onClick={() => {
					setActiveRow(activeRow === invoice.id ? null : invoice.id);
				}}>Actions</Button>
			}
			onClose={() => setActiveRow(null)}
		>
			<ActionList
				items={[
					{
						content: 'View Details',
						onAction: () => handleAction('view', invoice),
					},
				]}
			/>
		</Popover>
	), [activeRow, history]);

	const handleAction = (action, invoice) => {
		console.log(`Action: ${action}`, invoice);
		switch(action) {
			case 'view':
				let invoiceDetailUrl = `${routePrefix}/${invoice?.id}`;
				history.push(invoiceDetailUrl);
				break;
		}
		setActiveRow(null); // Close the popover
	};

	// Get data
	useEffect(() => {
		// Column type
		const colType = [];
		for (let i = 0; i < 8; i++) {
			colType.push("text");
		}

		// Heading
		const headings = [
			"ID",
			"Name",
			"Created At",
			"Status",
			"Total",
			"Actions",
		];

		setColumnContentTypes(colType);
		setHeadings(headings);

		let newRows =
			data?.invoices?.nodes?.length > 0
				? data?.invoices.nodes.map((item) => {
					const status = item?.status;

					let statusColor;
					for (let [key, value] of Object.entries(STATUS_BADGE)) {
						if (status === key) {
							statusColor = value;
						}
					}

					// Created
					const createdAt = moment(item?.createdAt).format(
						"YYYY-MM-DD HH:mm:ss"
					);

					let invoiceDetailUrl = `${routePrefix}/${item?.id}`;

					return [
						<div className="invoice-id">
							<Link url={invoiceDetailUrl} children={item?.id} />
						</div>,
						<div className="invoice-name">
							<span>{item?.name}</span>
						</div>,
						<div className="invoice-created_at">
							<span>{createdAt}</span>
						</div>,
						<div className="invoice-status">
							<Badge children={status} status={statusColor} />
						</div>,
						<div className="invoice-total">
							<span>${item?.totalPrice}</span>
						</div>,
						<div className="invoice-action">
							{renderActions(item)}
						</div>,
					];
				})
				: [];
		setRows(newRows);
	}, [data, activeRow]);

	let { limit, offset } = filter;
	const total = get(data, "invoices.total", 0);
	const totalPage = Math.ceil(total / limit);
	const page = offset / limit + 1;
	const aggregation = {
		page,
		totalPage,
		offset,
		limit,
		onChange: setFilter,
		total,
	};

	return (
		<Container>
			<DataTable
				columnContentTypes={columnContentTypes}
				headings={headings}
				rows={rows}
				verticalAlign="middle"
				hideScrollIndicator
				footerContent={
					<PaginationPolaris aggregation={aggregation} showTotal />
				}
			/>
		</Container>
	);
};
