import { Button, ButtonGroup, ChoiceList } from "@shopify/polaris";
import { isEqual } from "lodash";
import React from "react";
import styled from "styled-components";
import { WAREHOUSE_VALUES } from "../../../constants";
import { reducerFn } from "../../../helper";

const OPTIONS = WAREHOUSE_VALUES.map((value) => ({ label: value, value }));
export default function OrderLabel({ warehouse, onChange }) {
  const [state, setState] = React.useReducer(reducerFn, {
    isLabel: false,
    choice: warehouse || [],
  });

  const handleChange = React.useCallback(
    (warehouse) => {
      setState({ choice: warehouse });
      onChange(warehouse, state.isLabel);
    },
    [onChange, state.isLabel],
  );

  const handleBtnClick = React.useCallback(
    (isLabel) => () => {
      setState({ isLabel });
      onChange(state.choice, isLabel);
    },
    [state.choice, onChange],
  );

  React.useEffect(() => {
    if (!isEqual(warehouse, state.choice)) {
      setState({ choice: warehouse });
    }
  }, [warehouse]);

  return (
    <Wrapper>
      <ButtonGroup segmented>
        <Button
          primary={!state.isLabel}
          onClick={handleBtnClick(false)}
          children="Order"
        />
        <Button
          primary={!!state.isLabel}
          onClick={handleBtnClick(true)}
          children="Order Label"
        />
      </ButtonGroup>

      {state.isLabel ? (
        <ChoiceList
          choices={OPTIONS}
          onChange={handleChange}
          selected={state.choice}
          title="Choose Warehouse"
        />
      ) : null}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  > .Polaris-ChoiceList {
    margin-top: 1.5rem;

    .Polaris-ChoiceList__Choices {
      display: flex;
      column-gap: 2rem;
    }
  }
`;
