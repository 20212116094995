import { Page } from "@shopify/polaris";
import React from "react";
import styled from "styled-components";
import { InvoicesPolaris } from "../../components/seller/invoices/InvoicesPolaris";
import { getPathFromRouter } from "../../helper";

const Container = styled.div`
    margin: -1rem;
    @media (min-width: 640px) {
        margin: -1.5rem;
    }
`;

export const InvoicesPagePolaris = (...props) => {
    const path = React.useMemo(() => {
        return getPathFromRouter(props);
    }, [props]);

    return (
        <Container>
            <Page title="All Invoices" fullWidth>
                <InvoicesPolaris path={path} />
            </Page>
        </Container>
    );
};
