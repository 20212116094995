import { Badge } from "@shopify/polaris";
import { get } from "lodash";
import React from "react";

import { checkPlatform } from "../../../helper";
import { CustomLinkPolaris } from "../../shared/CustomLinkPolaris";
import { useTiktokInfoCtx } from "../context/LabelLinkContext";

export default function LabelLinkBadge({ node }) {
  const id = get(node, "id");
  const platform = get(node, "store.platform");
  const { isTiktok } = checkPlatform(platform);

  const { fileUrls } = useTiktokInfoCtx();
  if (!isTiktok) return null;

  let labelLink = get(node, "labelLink");
  if (!!labelLink && (fileUrls || {})[id]) {
    // order has label link => get link from MB
    labelLink = (fileUrls || {})[id];
  }

  if (!labelLink) return null;

  return (
    <CustomLinkPolaris href={labelLink}>
      <Badge status="success" children="Tiktok Label" />
    </CustomLinkPolaris>
  );
}
