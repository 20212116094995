import { gql } from "apollo-boost";

export const productBaseFragment = gql`
  fragment ProductBasse on ProductBase {
    id
    title
    slug
    baseCostDescription
    defaultContent
    defaultShortDescription
    details
    regularPrice
    salePrice
    sellerPrice
    fulfillment {
      fulfillmentId
      productId
    }
    images {
      id
      name
      url
      thumbnailUrl
    }
    suppliers {
      id
      firstName
      lastName
    }
    carriers {
      id
      code
      name
    }
    categories {
      id
      name
      description
    }
    supplierPricing {
      id
      userId
      price
    }
    carrierPricing {
      id
      carrierId
      price
    }
    attributes {
      name
      slug
      options
    }
    designPositions {
      id
      image {
        id
        thumbnailUrl
        url
      }
      name
      displayName
      sorting
      description
      artworkGuidelines {
        description
        file {
          url
          thumbnailUrl
          name
        }
      }
    }
    variants {
      id
      attributes {
        name
        slug
        option
      }
      carrierPricing {
        id
        carrierId
        price
      }
      regularPrice
      salePrice
      sellerPrice
      sorting
      status
    }
    status
  }
`;
