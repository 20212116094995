import React from "react";
import BrandStore from "../header/BrandStore";
import { Icon as LegacyIcon } from "@ant-design/compatible";
import { ControlOutlined } from "@ant-design/icons";
import { Menu, Skeleton } from "antd";
import { Link } from "react-router-dom";
import { Query } from "@apollo/react-components";
import { currentUser } from "../../components/EditProfile";
import history from "../../history";
import { handleError } from "../../helper";
import { LIST_STORE } from "../../components/seller/StoreSwitcher";
import { USER_ROLE, TEAM_ROLE } from "../../variable";
import { AppContext } from "../../context";
import TotalOrderMapping from "../../components/order/TotalOrderMapping";
import { CustomLinkPolaris } from "../../components/shared/CustomLinkPolaris";

const { SubMenu } = Menu;
const sizeIcon = { fontSize: "20px" };
const flexMenu = { display: "flex" };

const adminMenu = [
  {
    title: "Users",
    router: "/admin/users",
    key: "users",
    icon: "user",
    child: [
      {
        title: "All Users",
        router: "/admin/users",
      },
      {
        title: "Add New User",
        router: "/admin/users/add",
      },
    ],
  },
  {
    title: "Product Bases",
    icon: "code-sandbox",
    key: "product-base",
    router: "/admin/product-bases",
    child: [
      {
        title: "Add Product base",
        router: "/admin/product-bases/add",
        hidden: true,
      },
      {
        title: "Edit product base",
        router: "/admin/product-bases/edit/:id",
        hidden: true,
      },
      {
        title: "All Product Bases",
        router: "/admin/product-bases",
      },
      {
        title: "All Categories ",
        router: "/admin/categories",
      },
    ],
  },
  {
    title: "Orders",
    icon: "shopping",
    key: "orders",
    router: "/admin/orders",
    child: [
      {
        title: "Orders",
        router: "/admin/orders",
      },
      {
        title: "Supplier Orders",
        router: "/admin/orders/supplier",
      },
      {
        title: "Claims",
        router: "/admin/claims",
      },
    ],
  },
  {
    title: "Sellers",
    router: "/admin/sellers",
    key: "sellers",
    icon: "shopping-cart",
    child: [
      {
        title: "All Sellers",
        router: "/admin/sellers",
      },
    ],
  },

  {
    title: "Fulfillment",
    key: "fulfillment",
    icon: "build",
    router: "/admin/fulfillment",
    child: [
      {
        title: "Manage Fulfillment",
        router: "/admin/fulfillment",
        key: "fulfillment-manage",
      },
      {
        title: "Manage Suppliers",
        router: "/admin/suppliers",
        key: "suppliers",
      },
    ],
  },

  {
    title: "Billings",
    key: "billings",
    icon: "shopping-cart",
    router: "/admin/billings/overview",
    child: [
      {
        title: "Overview",
        router: "/admin/billings/overview",
      },
      {
        title: "All Deposits",
        router: "/admin/billings/deposits",
      },
      {
        title: "All Transactions",
        router: "/admin/billings/transactions",
      },
	  {
        title: "All Invoices",
        router: "/admin/billings/invoices",
      },
    ],
  },
  {
    title: "Manager Files",
    key: "managerFiles",
    icon: "file-text",
    router: "/admin/manager-files/import",
    child: [
      {
        title: "Import Files",
        router: "/admin/manager-files/import",
      },
      {
        title: "Export Files",
        router: "/admin/manager-files/export",
      },
      {
        title: "Export Templates",
        router: "/admin/manager-files/export-templates",
      },
    ],
  },
  {
    title: "Reports",
    router: "/admin/reports",
    key: "reports",
    icon: "skin",
  },
  {
    title: "Carriers",
    key: "carriers",
    icon: "car",
    router: "/admin/carriers",
    child: [
      {
        title: "All Carriers",
        router: "/admin/carriers",
      },
      {
        title: "Add Carrier",
        router: "/admin/add-carriers",
      },
    ],
  },
  {
    title: "System",
    key: "system",
    icon: "setting",
    router: "/admin/system/settings",
    child: [
      {
        title: "Settings",
        router: "/admin/system/settings",
      },
      {
        title: "Cron Jobs",
        router: "/admin/system/cron-jobs",
      },
    ],
  },
];

const sellerMenu = [
  {
    title: "Sales Channels",
    key: "sales_channels",
    icon: "shop",
    child: [
      {
        title: "Online Stores",
        router: "/seller/stores",
      },
      {
        title: "Add Store",
        router: "/seller/store/add",
        hidden: true,
      },
      {
        title: "Amazon",
        router: "/seller/stores/amazon",
      },
      {
        title: "Amazon",
        router: "/seller/stores/amazon/add",
        hidden: true,
      },
      {
        title: "Ebay",
        router: "/seller/stores/ebay",
      },
	  {
        title: "Etsy",
        router: "/seller/stores/etsy",
      },
      {
        title: "Etsy",
        router: "/seller/stores/etsy/add",
        hidden: true,
      },
    ],
  },
  {
    title: "Products",
    router: "/seller/products",
    key: "seller-products",
    icon: "inbox",
    child: [
      {
        title: "All Products",
        router: "/seller/products",
      },
      {
        title: "Collections",
        router: "/seller/collections",
      },
      {
        title: "Tags",
        router: "/seller/tags",
      },
      {
        title: "Product Feeds",
        router: "/seller/products/feeds",
      },
      {
        title: "Add Product Feed",
        router: "/seller/products/feeds/add",
        hidden: true,
      },
      {
        hidden: true,
        title: "Edit Product Campaign",
        router: "/seller/products/campaign/:id",
      },
    ],
  },
  {
    title: "Orders",
    icon: "shopping",
    key: "orders",
    router: "/seller/orders",
    child: [
      {
        title: "Orders",
        router: "/seller/orders",
      },
      {
        title: "Mapping Orders",
        router: "/seller/mapping",
        badge: <TotalOrderMapping />,
      },
      {
        title: "Claims",
        router: "/seller/claims",
      },
    ],
  },
  {
    title: "Members",
    icon: "team",
    router: "/seller/team-members",
    key: "team-members",
    child: [
      {
        title: "All Members",
        router: "/seller/team-members",
      },
      {
        title: "Add Member",
        router: "/seller/add-team-member",
      },
    ],
  },
  {
    title: "Suppliers",
    router: "/seller/suppliers",
    key: "suppliers",
    icon: "build",
    child: [
      {
        title: "All Suppliers",
        router: "/seller/suppliers",
      },
      {
        title: "Add Supplier",
        router: "/seller/supplier/add",
      },
    ],
  },
  {
    title: "Billings",
    key: "seller-billings",
    router: "/seller/billings/deposits",
    icon: "shopping-cart",
    child: [
      {
        title: "All Deposits",
        router: "/seller/billings/deposits",
      },
      {
        title: "Transactions",
        router: "/seller/billings/transactions",
      },
	  {
        title: "Invoices",
        router: "/seller/billings/invoices",
      },
    ],
  },
  {
    title: "Tasks",
    icon: "ordered-list",
    router: "/seller/design-tasks",
    key: "design-tasks",
    child: [
      {
        title: "Create Designs",
        router: "/seller/design-tasks",
      },
    ],
  },
  {
    title: "Tools",
    icon: "tool",
    router: "/tools/product-crawlers",
    key: "tools",
    child: [
      {
        title: "Product Crawlers",
        router: "/tools/product-crawlers",
      },
      {
        title: "Product Assortment",
        router: "/tools/product-assortment",
      },
      {
        title: "Push Products",
        router: "/tools/product-push",
      },
    ],
  },
  {
    title: "Apps",
    icon: "appstore",
    router: "/seller/apps",
    key: "apps",
    child: [
      {
        title: "All Apps",
        router: "/seller/apps",
      },
    ],
  },
];

const supporterAdminMenu = [
  {
    title: "Orders",
    icon: "shopping",
    key: "orders",
    router: "/admin/orders",
    child: [
      {
        title: "Orders",
        router: "/admin/orders",
      },
      {
        title: "Supplier Orders",
        router: "/admin/orders/supplier",
      },
      {
        title: "Claims",
        router: "/admin/claims",
      },
    ],
  },
  {
    title: "Manager Files",
    key: "managerFiles",
    icon: "file-text",
    router: "/admin/manager-files/import",
    child: [
      {
        title: "Import Files",
        router: "/admin/manager-files/import",
      },
      {
        title: "Export Files",
        router: "/admin/manager-files/export",
      },
    ],
  },
];

const storeManagerMenu = [
  {
    title: "Products",
    router: "/seller/products",
    key: "seller-products",
    icon: "inbox",
    child: [
      {
        title: "All Products",
        router: "/seller/products",
      },
      {
        title: "Collections",
        router: "/seller/collections",
      },
      {
        title: "Tags",
        router: "/seller/tags",
      },
      {
        title: "Product Feeds",
        router: "/seller/products/feeds",
      },
      {
        title: "Add Product Feed",
        router: "/seller/products/feeds/add",
        hidden: true,
      },
      {
        hidden: true,
        title: "Edit Product Campaign",
        router: "/seller/products/campaign/:id",
      },
    ],
  },
  {
    title: "Orders",
    icon: "shopping",
    key: "orders",
    router: "/store-manager/orders",
    child: [
      {
        title: "Orders",
        router: "/store-manager/orders",
      },
      {
        title: "Mapping Orders",
        router: "/store-manager/mapping",
        badge: <TotalOrderMapping />,
      },
      {
        title: "Claims",
        router: "/seller/claims",
      },
    ],
  },
  {
    title: "Tasks",
    icon: "ordered-list",
    router: "/seller/design-tasks",
    key: "design-tasks",
    child: [
      {
        title: "Create Designs",
        router: "/seller/design-tasks",
      },
    ],
  },
  {
    title: "Tools",
    icon: "tool",
    router: "/tools/product-crawlers",
    key: "tools",
    child: [
      {
        title: "Product Crawlers",
        router: "/tools/product-crawlers",
      },
      {
        title: "Product Assortment",
        router: "/tools/product-assortment",
      },
      {
        title: "Push Products",
        router: "/tools/product-push",
      },
    ],
  },
];

const supporterSellerMenu = [
  {
    title: "Products",
    router: "/seller/products",
    key: "seller-products",
    icon: "inbox",
    child: [
      {
        title: "All Products",
        router: "/seller/products",
      },
      {
        title: "Collections",
        router: "/seller/collections",
      },
      {
        title: "Tags",
        router: "/seller/tags",
      },
    ],
  },
  {
    title: "Orders",
    icon: "shopping",
    key: "orders",
    router: "/seller/orders",
    child: [
      {
        title: "Orders",
        router: "/seller/orders",
      },
      {
        title: "Mapping Orders",
        router: "/seller/mapping",
        badge: <TotalOrderMapping />,
      },
      {
        title: "Claims",
        router: "/seller/claims",
      },
    ],
  },
  {
    title: "Tools",
    icon: "tool",
    router: "/tools/product-assortment",
    key: "tools",
    child: [
      {
        title: "Product Assortment",
        router: "/tools/product-assortment",
      },
    ],
  },
];

const TeamSellerMenu = [
  {
    title: "Stores",
    router: "/admin/dashboard",
    key: "stores",
    icon: "shop",
    child: [
      {
        title: "Add Store",
        router: "/seller/store/add",
        icon: "skin",
      },
      {
        title: "All Stores",
        router: "/seller/stores",
        icon: "shopping-cart",
      },
    ],
  },
  {
    title: "Product Bases",
    icon: "code-sandbox",
    key: "product-base",
    child: [
      {
        title: "Add New",
        router: "/seller/product-bases/add",
        icon: "skin",
      },
      {
        title: "Product Bases",
        router: "/seller/product-bases",
        icon: "shopping-cart",
      },
    ],
  },
  {
    title: "Products",
    router: "/seller/products",
    key: "seller-products",
    icon: "inbox",
    child: [
      {
        title: "Add Product",
        router: "/seller/products/add",
        hidden: true,
      },
      {
        title: "Add Product Campaign",
        router: "/seller/products/add-campaign",
        hidden: true,
      },
      {
        title: "Edit Product",
        router: "/seller/products/edit/:id",
        hidden: true,
      },
      {
        title: "All Products",
        router: "/seller/products",
      },
      {
        title: "Add Product",
        router: "/seller/product/add-product",
      },
      {
        title: "Add Campaign",
        router: "/seller/product/add-campaign",
      },
      {
        title: "Collections",
        router: "/seller/product/add-collections",
      },
      {
        title: "Tags",
        router: "/seller/product/add-tags",
      },
    ],
  },
  {
    title: "Orders",
    icon: "shopping",
    key: "orders",
    child: [
      {
        title: "Orders",
        router: "/seller/orders",
        icon: "skin",
      },
      {
        title: "Claims",
        router: "/seller/claims",
        icon: "shopping-cart",
      },
    ],
  },
  {
    title: "Members",
    icon: "team",
    key: "team-members",
    child: [
      {
        title: "Add Team Member",
        router: "/seller/add-team-member",
      },
      {
        title: "All Team Member",
        router: "/seller/team-members",
      },
    ],
  },
  {
    title: "Suppliers",
    router: "/seller/suppliers",
    key: "suppliers",
    icon: "build",
  },
  {
    title: "Billings",
    key: "seller-billings",
    icon: "shopping-cart",
    router: "/seller/billings/deposits",
    child: [
      {
        title: "All Deposits",
        router: "/seller/billings/deposits",
      },
      {
        title: "Transactions",
        router: "/seller/billings/transactions",
      },
    ],
  },
  {
    title: "Tools",
    icon: "tool",
    key: "tools",
    child: [
      {
        title: "Product Crawlers",
        router: "/tools/product-crawlers",
      },
      {
        title: "Product Assortment",
        router: "/tools/product-assortment",
      },
    ],
  },
];

const supplierMenu = [
  {
    title: "Orders",
    icon: "shopping",
    key: "orders",
    router: "/supplier/orders",
    child: [
      {
        title: "All Orders",
        router: "/supplier/orders",
      },
      {
        title: "Claims",
        router: "/supplier/claims",
      },
      {
        title: "Import Tracking",
        router: "/supplier/import-tracking",
      },
    ],
  },
  {
    title: "Billings",
    key: "supplier-billings",
    icon: "shopping-cart",
    router: "/supplier/billings/deposits",
    child: [
      {
        title: "All Deposits",
        router: "/supplier/billings/deposits",
      },
      {
        title: "Transactions",
        router: "/supplier/billings/transactions",
      },
    ],
  },
  {
    title: "Manager Files",
    key: "manager-files",
    icon: "file",
    router: "/supplier/imports",
    child: [
      {
        title: "Import Files",
        router: "/supplier/imports",
      },
      {
        title: "Exports Files",
        router: "/supplier/export-tracking",
      },
      {
        title: "Export Templates",
        router: "/supplier/manager-files/export-templates",
      },
    ],
  },
];

const designerMenu = [
  {
    title: "Tasks",
    icon: "ordered-list",
    router: "/designer/design-tasks",
    key: "design-tasks",
    child: [
      {
        title: "Create Designs",
        router: "/designer/design-tasks",
      },
    ],
  },
];

class Sidebar extends React.Component {
  renderMenu = (theMenu) => {
    return theMenu.map((menu) => {
      return menu.child ? (
        <SubMenu
          key={menu.key}
          className="top-menu"
          onTitleClick={() => {
            history.push(menu.router);
          }}
          title={
            <Link to={menu.router}>
              {menu.icon && <LegacyIcon style={sizeIcon} type={menu.icon} />}
              <span>{menu.title}</span>
            </Link>
          }
        >
          {menu.child.map((sub) => {
            return (
              <Menu.Item
                hidden={sub.hidden}
                key={sub.router}
                className="menu-child-item"
              >
                <Link to={sub.router}>
                  {sub.icon && <LegacyIcon style={sizeIcon} type={sub.icon} />}
                  <span>{sub.title}</span>
                  {sub.badge ? sub.badge : null}
                </Link>
              </Menu.Item>
            );
          })}
        </SubMenu>
      ) : (
        <Menu.Item key={menu.router} className="top-menu-without-child">
          <Link to={menu.router}>
            {menu.icon && <LegacyIcon style={sizeIcon} type={menu.icon} />}
            <span>{menu.title}</span>
          </Link>
        </Menu.Item>
      );
    });
  };

  render() {
    const { menuMobile, match } = this.props;
    let openMenuKeys = [];
    let selectedKeys = [];
    let mapMenuKeys = {};
    let mapSelectables = {};
    [
      ...adminMenu,
      ...TeamSellerMenu,
      ...sellerMenu,
      ...supplierMenu,
      ...storeManagerMenu,
      ...supporterAdminMenu,
      ...designerMenu,
    ].forEach((m) => {
      mapMenuKeys[m.router] = m.key;
      mapSelectables[m.router] = m.key;
      if (m.child && m.child.length) {
        m.child.forEach((c) => {
          mapMenuKeys[c.router] = m.key;
          mapSelectables[c.router] = c.router;
        });
      }
    });
    if (match) {
      if (mapMenuKeys[match.path]) {
        openMenuKeys = [mapMenuKeys[match.path]];
      }
      if (mapSelectables[match.path]) {
        selectedKeys = [mapSelectables[match.path]];
      }
    }
    return (
      <Query query={currentUser}>
        {({ error, loading, data }) => {
          if (loading)
            return (
              <div className="p-4">
                <Skeleton active />
              </div>
            );
          if (error) return <div>Error:{handleError(error.toString())}</div>;
          const userRoles = data.me.roles;
          const teamRole = data.me.teamUser ? data.me.teamUser.role : null;
          return (
            <AppContext.Consumer>
              {({ store }) => (
                <Query
                  query={LIST_STORE}
                  skip={!userRoles.includes(USER_ROLE.Seller)}
                >
                  {({ data, loading, error }) => {
                    if (loading)
                      return (
                        <div className="p-4">
                          <Skeleton active />
                        </div>
                      );
                    return teamRole === USER_ROLE.StoreManager &&
                      !store ? null : (
                      <Menu
                        selectedKeys={selectedKeys}
                        defaultOpenKeys={openMenuKeys}
                        mode="inline"
                        className={
                          "sidebar " + ((menuMobile && "sidebar-mobile") || "")
                        }
                      >
                        {menuMobile ? (
                          <Menu.Item
                            key="sub10"
                            className="items-center rounded logo-mobile"
                            style={flexMenu}
                          >
                            <BrandStore />
                          </Menu.Item>
                        ) : null}
                        {menuMobile && data && teamRole !== "Store Manager" ? (
                          <Menu.SubMenu
                            className="top-menu"
                            key="sub11"
                            title={
                              <span>
                                <ControlOutlined style={{ fontSize: 20 }} />
                                <span className="font-medium text-color">
                                  Tee Store
                                </span>
                              </span>
                            }
                          >
                            {data &&
                              data.storeListOfMe &&
                              data.storeListOfMe.nodes &&
                              data.storeListOfMe.nodes.map((store) => (
                                <Menu.Item
                                  key={store.id}
                                  className="menu-child-item"
                                >
                                  <CustomLinkPolaris href={store.domain}>
                                    {store.title}
                                  </CustomLinkPolaris>
                                </Menu.Item>
                              ))}
                          </Menu.SubMenu>
                        ) : null}

                        {userRoles.map((userRole) => {
                          const userRoles = {
                            Administrator: this.renderMenu(adminMenu),
                            [USER_ROLE.Supporter]: this.renderMenu(
                              supporterAdminMenu
                            ),
                            Seller: {
                              [USER_ROLE.StoreManager]: store
                                ? this.renderMenu(storeManagerMenu)
                                : null,
                              [TEAM_ROLE.Supporter]: this.renderMenu(
                                supporterSellerMenu
                              ),
                              [USER_ROLE.Admin]: this.renderMenu(sellerMenu),
                              [TEAM_ROLE.Designer]: this.renderMenu(
                                designerMenu
                              ),
                            },
                            Supplier: this.renderMenu(supplierMenu),
                          };

                          return teamRole
                            ? userRoles[userRole][teamRole]
                            : userRoles[userRole];
                          // switch (userRole) {
                          //   case "Administrator":
                          //     return this.renderMenu(adminMenu);
                          //   case "Seller":
                          //     if (teamRole === USER_ROLE.StoreManager) {
                          //       return store
                          //         ? this.renderMenu(storeManagerMenu)
                          //         : null;
                          //     } else return this.renderMenu(sellerMenu);
                          //   case "Supplier":
                          //     return this.renderMenu(supplierMenu);
                          //   default:
                          //     return null;
                          // }
                        })}
                      </Menu>
                    );
                  }}
                </Query>
              )}
            </AppContext.Consumer>
          );
        }}
      </Query>
    );
  }
}

export default Sidebar;
