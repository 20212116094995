import { gql } from "apollo-boost";

export const BULK_SWITCH_FULFILLMENT = gql`
  mutation bulkSwitchFulfillment($input: BulkSwitchFulfillmentInput!) {
    bulkSwitchFulfillment(input: $input) {
      Switched
      Messages {
        id
        error
      }
    }
  }
`;

export const DELETE_FULFILLMENT_CONFIGURE = gql`
  mutation deleteFulfillmentConfigure($slug: String!, $storeId: String) {
    deleteFulfillmentConfigure(slug: $slug, storeId: $storeId)
  }
`;

export const CREATE_FULFILLMENT_CONFIGURE = gql`
  mutation createFulfillmentConfigure($input: NewFulfillmentConfig!) {
    createFulfillmentConfigure(input: $input) {
      id
      fulfillmentId
      apiKey
      apiSecret
    }
  }
`;

export const UPDATE_FULFILLMENT_CONFIGURE = gql`
  mutation updateFulfillmentConfigure($input: UpdateFulfillmentConfig!) {
    updateFulfillmentConfigure(input: $input) {
      id
    }
  }
`;

export const UPDATE_PRITIFY_AUTO_PRODUCTION = gql`
  mutation updatePrintifyAutoProduction(
    $appIntegrationID: ID!
    $isAutoProduction: Boolean!
  ) {
    updatePrintifyAutoProduction(
      appIntegrationID: $appIntegrationID
      isAutoProduction: $isAutoProduction
    ) {
      id
    }
  }
`;

export const UPDATE_TEEZILY_AUTO_PRODUCTION = gql`
  mutation updateTeezilyAutoProduction(
    $appIntegrationID: ID!
    $isAutoProduction: Boolean!
  ) {
    updateTeezilyAutoProduction(
      appIntegrationID: $appIntegrationID
      isAutoProduction: $isAutoProduction
    ) {
      id
    }
  }
`;

export const VALIDATE_BURGERPRINTS_APIKEY = gql`
  mutation validateBurgerPrintsApiKey($apiKey: String!) {
    validateBurgerPrintsApiKey(apiKey: $apiKey)
  }
`;

export const CANCEL_IN_FULFILLMENT_SERVICE = gql`
  mutation cancelInFulfillmentService($orderID: String!) {
    cancelInFulfillmentService(orderID: $orderID)
  }
`;

export const SYNC_DREAMSHIP_STATUS = gql`
  mutation syncDreamshipStatus($orderID: String!) {
    syncDreamshipStatus(orderID: $orderID)
  }
`;

export const CREATE_ORDER_LABEL_FOR_CUSTOM_FULFILLMENT = gql`
  mutation createOrderLabelForCustomFulfillment($orderIDs: [String!]!) {
    createOrderLabelForCustomFulfillment(orderIDs: $orderIDs)
  }
`;

export const BUY_TIKTOK_ORDER_LABEL = gql`
  mutation buyTiktokOrderLabel($orderIDs: [String!]!) {
    buyTiktokOrderLabel(orderIDs: $orderIDs)
  }
`;
