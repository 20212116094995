import { useLazyQuery } from "@apollo/react-hooks";
import { Card, Loading } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { get, isEqual } from "lodash";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { AppContext } from "../../context";
import {
  checkRole,
  convertObjectToParams,
  convertStringToObject,
  convertToOffset,
  convertToPaged,
  getCookie,
  handleError,
  matchPathName,
  removeFieldWithoutFilter,
  setCookie,
} from "../../helper";
import history from "../../history";
import ProductFiltersPolaris, {
  No,
  Yes,
} from "../../pages/seller/ProductFiltersPolaris";
import { COMMON_FILTER, TEAM_ROLE } from "../../variable";
import { EmptyStatePolaris } from "../shared/EmptyStatePolaris";
import { SkeletonPagePolaris } from "../shared/SkeletonPagePolaris";
import { TableProductPolaris } from "./TableProductPolaris";
import { useProductsPolarisContext } from "../../pages/seller/ProductsPagePolaris";

export const LIST_SELLER_PRODUCTS_QUERY = gql`
  query products($filter: ProductFilter) {
    products(filter: $filter) {
      total
      nodes {
        id
        title
        shortTitle
        amzTitle
        sku
        designStatus
        personalized
        status
        sku
        designStatus
        totalOrders
        productStores {
          id
          store {
            id
            platform
          }
        }
        productBases {
          id
          title
        }
        mainImageId
        images {
          id
          productBaseId
          file {
            id
            url
            thumbnailUrl
          }
        }
        mainImage {
          id
          thumbnailUrl
          url
        }
        tags {
          id
          name
        }
        collections {
          id
          name
        }
        fields {
          id
          name
          title
          type
          extraFee
          configure
          sorting
        }
      }
    }
  }
`;

const FIELD_FILTER = [
  ...COMMON_FILTER,
  "productBaseId",
  "storeId",
  "collections",
  "tags",
  "authorId",
  "personalized",
  "divided",
  "usedStatus",
  "refURL",
  "sortBy",
  "createdVia",
  "notYetPushSaleChannel",
  "hasShortTitle",
  "isMainTitleUpdated",
  "autoListed",
  "hasOrder",
];

export const ProductsPolaris = ({
  hideFilterStore,
  isMarketplaceManager,
  path,
  setExportState,
}) => {
  const isMatchPathName = useMemo(() => matchPathName(path), [path]);
  const initFilter = useMemo(() => {
    let initialFilter = {
      ...convertStringToObject(history.location.search),
    };
    if (initialFilter) {
      if (initialFilter.collections) {
        initialFilter.collections = [initialFilter.collections];
      }
      if (initialFilter.tags) {
        initialFilter.tags = [initialFilter.tags];
      }

      if (initialFilter.productType) {
        if (initialFilter.productType === "normal") {
          initialFilter.personalized = false;
        }
        if (initialFilter.productType === "personalized") {
          initialFilter.personalized = true;
        }
      }
      if (
        initialFilter.productDivision &&
        initialFilter.productDivision === "divided"
      ) {
        initialFilter.divided = true;
      }

      if (initialFilter.usedStatus) {
        if (initialFilter.usedStatus === "Used") {
          initialFilter.usedStatus = true;
        }
        if (initialFilter.usedStatus === "Unused") {
          initialFilter.usedStatus = false;
        }
      }

      initialFilter.hasOrder = paramToBool(initialFilter.hasOrder);
      initialFilter.hasShortTitle = paramToBool(initialFilter.hasShortTitle);
      initialFilter.isMainTitleUpdated = paramToBool(
        initialFilter.isMainTitleUpdated,
      );

      let limit = initialFilter.limit;
      limit = Number(limit);
      if (!limit) {
        limit = 10;
      }
      initialFilter.limit = limit;

      if (initialFilter.designStatus) {
        if (initialFilter.designStatus === "All") {
          initialFilter.designStatus = null;
        }
      }

      if (initialFilter.paged) {
        initialFilter.offset = convertToOffset(
          initialFilter.limit,
          Math.round(initialFilter.paged),
        );
      }
      initialFilter = removeFieldWithoutFilter(initialFilter, FIELD_FILTER);
    }

    return initialFilter;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMatchPathName]);

  const { store, currentUser } = useContext(AppContext);
  const userRole = get(currentUser, "teamUser.role", null);
  // const userId = currentUser?.id;
  let storeManagerSpecial = true;
  // if (STORE_MANAGE_SHOW_ALL_PRODUCTS.includes(userId)) {
  //   storeManagerSpecial = true;
  // }
  const isTeamLead = [
    TEAM_ROLE.Admin,
    TEAM_ROLE.MarketplaceManager,
    TEAM_ROLE.StoreManager,
  ].includes(userRole);

  const { isIdea, isIdeaLeader, isStoreManager } = checkRole(currentUser);
  const isRoleIdea = isIdea || isIdeaLeader;

  const [filter, setFilter] = useState({
    limit: Number(getCookie("perPageProducts")) || 20,
    offset: 0,
    search: null,
    productBaseId: null,
    storeId: null,
    collections: null,
    tags: null,
    personalized: null,
    authorId: null,
    createTime: null,
    autoListedTime: null,
    divided: null,
    usedStatus: null,
    designStatus: null,
    refURL: null,
    sort: "DESC",
    sortBy: "total_orders",
    createdVia: null,
    notYetPushSaleChannel: null,
    hasShortTitle: null,
    isMainTitleUpdated: null,
    autoListed: null,
    hasOrder: null,
    ...initFilter,
  });

  /*const { data, loading, error, refetch, networkStatus } = useQuery(
    LIST_SELLER_PRODUCTS_QUERY,
    {
      variables: {
        filter: {
          ...filter,
          storeId: store
            ? isMarketplace && store.id === "all"
              ? null
              : store.id
            : filter.storeId,
        },
      },
      notifyOnNetworkStatusChange: true,
    }
  );*/

  const { products, setState } = useProductsPolarisContext();
  const [fetchProducts, { data, loading, error, refetch, networkStatus }] =
    useLazyQuery(LIST_SELLER_PRODUCTS_QUERY, {
      variables: {
        filter: {
          ...filter,
          storeId: store
            ? isMarketplaceManager && store.id === "all"
              ? null
              : store.id
            : filter.storeId,
          authorId:
            isStoreManager && !storeManagerSpecial
              ? currentUser?.id
              : filter.authorId,
        },
      },
      notifyOnNetworkStatusChange: true,
      onCompleted: (res) => {
        const products = getProducts(res);
        setState({ products });
      },
    });

  useEffect(() => {
    fetchProducts();
    let {
      offset,
      limit,
      createdTime,
      autoListedTime,
      personalized,
      divided,
      usedStatus,
      designStatus,
      collections,
      tags,
      hasShortTitle,
      isMainTitleUpdated,
      hasOrder,
      ...rest
    } = filter;
    let productType = null;
    if (personalized === false) {
      productType = "normal";
    } else if (personalized === true) {
      productType = "personalized";
    }
    let productDivision = null;
    if (divided) {
      productDivision = "divided";
    }
    let newUsedStatus = null;
    if (usedStatus) {
      newUsedStatus = "Used";
    } else if (usedStatus === false) {
      newUsedStatus = "Unused";
    }
    let params = null;
    let paged = convertToPaged(limit, offset);
    let paramDesignStatus = null;
    if (designStatus) {
      paramDesignStatus = designStatus;
    }

    const newHasShortTitle = boolToParam(hasShortTitle);
    const newIsMainTitle = boolToParam(isMainTitleUpdated);
    const newHasOrder = boolToParam(hasOrder);

    params = convertObjectToParams({
      limit,
      paged,
      productType,
      productDivision,
      usedStatus: newUsedStatus,
      designStatus: paramDesignStatus,
      hasShortTitle: newHasShortTitle,
      isMainTitleUpdated: newIsMainTitle,
      hasOrder: newHasOrder,
      ...rest,
    });

    history.push(`${history.location.pathname}?${params}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  const handleFilterChange = useCallback(
    (
      productBaseId,
      storeId,
      collectionId,
      tagId,
      search,
      productType,
      authorId,
      createTime,
      divided,
      usedStatus,
      designStatus,
      refURL,
      sort,
      sortBy,
      createdVia,
      notYetPushSaleChannel,
      hasShortTitle,
      isMainTitleUpdated,
      autoListed,
      autoListedTime,
      hasOrder,
    ) => {
      setFilter((prevState) => {
        if (
          !isEqual(prevState.productBaseId, productBaseId) ||
          !isEqual(prevState.storeId, storeId) ||
          !isEqual(prevState.collections, collectionId) ||
          !isEqual(prevState.tags, tagId) ||
          !isEqual(prevState.search, search) ||
          !isEqual(prevState.personalized, productType.value) ||
          !isEqual(prevState.authorId, authorId) ||
          !isEqual(prevState.createTime, createTime) ||
          !isEqual(prevState.divided, divided) ||
          !isEqual(prevState.usedStatus, usedStatus) ||
          !isEqual(prevState.designStatus, designStatus) ||
          !isEqual(prevState.refURL, refURL) ||
          !isEqual(prevState.sort, sort) ||
          !isEqual(prevState.sortBy, sortBy) ||
          !isEqual(prevState.createdVia, createdVia) ||
          !isEqual(prevState.hasShortTitle, hasShortTitle) ||
          !isEqual(prevState.isMainTitleUpdated, isMainTitleUpdated) ||
          !isEqual(prevState.notYetPushSaleChannel, notYetPushSaleChannel) ||
          !isEqual(prevState.autoListed, autoListed) ||
          !isEqual(prevState.autoListedTime, autoListedTime) ||
          !isEqual(prevState.hasOrder, hasOrder)
        ) {
          const newFilter = {
            ...prevState,
            productBaseId,
            storeId,
            collections: collectionId,
            tags: tagId,
            search,
            personalized: productType.value,
            authorId,
            createTime,
            divided,
            usedStatus,
            designStatus,
            refURL,
            sort,
            sortBy,
            createdVia,
            notYetPushSaleChannel,
            hasShortTitle,
            isMainTitleUpdated,
            autoListed,
            autoListedTime,
            hasOrder,
            offset: 0,
          };
          setExportState && setExportState({ show: true, filter: newFilter });
          return newFilter;
        }
        return prevState;
      });
    },
    [setExportState],
  );

  const loadingMarkup = loading && <Loading />;

  return (
    <>
      {loadingMarkup}
      <Card sectioned>
        <ProductFiltersPolaris
          propsFilters={filter}
          hideFilterStore={hideFilterStore}
          isMarketplaceManager={isMarketplaceManager}
          isStoreManager={isStoreManager}
          storeManagerSpecial={storeManagerSpecial}
          isTeamLead={isTeamLead}
          isRoleIdea={isRoleIdea}
          setProductFilters={handleFilterChange}
        />
      </Card>
      <Card>
        {error && <div>Error: {handleError(error.toString())}</div>}
        {loading && 4 !== networkStatus ? (
          <SkeletonPagePolaris />
        ) : data?.products?.nodes?.length > 0 ? (
          <TableProductPolaris
            data={data}
            networkStatus={networkStatus}
            filter={filter}
            refetch={refetch}
            setFilter={(offset, limit) => {
              if (filter.limit !== limit || filter.offset !== offset) {
                setFilter((prevState) => ({
                  ...prevState,
                  offset,
                  limit,
                }));
              }
              setCookie("perPageProducts", limit, 100);
            }}
          />
        ) : (
          <EmptyStatePolaris />
        )}
      </Card>
    </>
  );
};

function boolToParam(value) {
  if (typeof value !== "boolean") return null;

  return value ? Yes : No;
}

function paramToBool(value) {
  if (typeof value !== "string") return null;

  return value === Yes;
}

function getProducts(res) {
  const nodes = get(res, "products.nodes") || [];

  const m = new Map();
  for (let node of nodes) {
    if (!node || !node.title) continue;

    const item = {
      title: node.title,
      images: (node.images || [])
        .filter((i) => !!i.file)
        .map((i) => i.file?.url)
        .filter(Boolean),
    };
    m.set(node.id, item);
  }

  return Array.from(m.values());
}
