import { gql } from "apollo-boost";
import { StoreFragment } from "../fragments";

export const CREATE_ACCOUNT_SALE_CHANNEL = gql`
  mutation createAccountSaleChannel($input: NewAccount!) {
    createAccountSaleChannel(input: $input) {
      ...StoreFragment
    }
  }
  ${StoreFragment}
`;

export const DELETE_STORE = gql`
  mutation deleteStore($id: ID!) {
    deleteStore(id: $id)
  }
`;

export const BULK_DELETE_STORE = gql`
mutation bulkDeleteStore($ids: [ID!]!) {
    bulkDeleteStore(ids: $ids)
  }
`;

export const UPDATE_STORE = gql`
  mutation updateStore($input: EditStore!) {
    updateStore(input: $input) {
      ...StoreFragment
    }
  }
  ${StoreFragment}
`;

export const MARK_CHECKPOINT = gql`
  mutation markCheckpointStoreFacebook(
    $emailStore: String!
    $isCheckpoint: Boolean!
  ) {
    markCheckpointStoreFacebook(
      emailStore: $emailStore
      isCheckpoint: $isCheckpoint
    ) {
      ...StoreFragment
    }
  }
  ${StoreFragment}
`;

export const MARK_PUSHED_TO_STORES = gql`
  mutation markPushedToStores(
    $productID: ID!
    $input: [MarkPushedToStoreConfig!]
  ) {
    markPushedToStores(productID: $productID, input: $input) {
      productID
      storeID
      pushedProductID
      storePlatform
    }
  }
`;

export const FETCH_ETSY_ORDERS = gql`
  mutation fetchEtsyOrders($storeId: String!, $limit: Int) {
    fetchEtsyOrders(storeId: $storeId, limit: $limit)
  }
`;

export const FETCH_ORDER = gql`
  mutation fetchOrder(
    $storeId: String!
    $orderId: String!
    $lineItemID: String
  ) {
    fetchOrder(storeId: $storeId, orderId: $orderId, lineItemID: $lineItemID)
  }
`;

export const CREATE_SHOPIFY_STORE = gql`
  mutation createShopifyStore($input: NewStore!) {
    createShopifyStore(input: $input) {
      id
      title
      domain
      platform
    }
  }
`;

export const RE_GEN_STORE_API_KEY = gql`
  mutation reGenStoreApiKey($ids: [ID!]!) {
    reGenStoreApiKey(ids: $ids) {
      id
      apiKey
    }
  }
`;

export const TIKTOK_FETCH_ORDERS_BY_ID = gql`
    mutation tiktokFetchOrdersById($storeID: String!, $ids: [String!]!) {
        tiktokFetchOrdersById(storeID: $storeID, ids: $ids)
    }
`;

export const TIKTOK_FETCH_ORDERS_BY_STORE = gql`
  mutation tiktokFetchOrdersByStore($storeID: String!) {
    tiktokFetchOrdersByStore(storeID: $storeID)
  }
`;

export const MARK_STORE_DEACTIVATED = gql`
  mutation markStoreDeactivated($storeId: ID!) {
    markStoreDeactivated(storeId: $storeId) {
      id
      status
    }
  }
`;

export const MARK_STORE_ACTIVATED = gql`
  mutation markStoreActivated($storeId: ID!) {
    markStoreActivated(storeId: $storeId) {
      id
      status
    }
  }
`;

export const EXPORT_TIKTOK_PRODUCT_BY_STORE = gql`
  mutation exportTiktokProductByStore($storeID: String!, $emailToReceiveFileURL: String!) {
    exportTiktokProductByStore(storeID: $storeID, emailToReceiveFileURL:$emailToReceiveFileURL)
  }
`;

