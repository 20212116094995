import { useMutation } from "@apollo/react-hooks";
import { Modal } from "@shopify/polaris";
import React from "react";

import { BUY_TIKTOK_ORDER_LABEL } from "../../../graphql/mutations";
import { useToastContext } from "../../shared/ToastContext";
import { handleError } from "../../../helper";
import useTimeout from "../../../hooks/useTimeout";

export default function BuyTiktokLabel({
  orderIDs,
  open,
  onClose,
  handleCompleted,
}) {
  const { setNotify, toggleToast } = useToastContext();

  const [setDelay] = useTimeout();
  const [buyLabel, { loading }] = useMutation(BUY_TIKTOK_ORDER_LABEL, {
    onCompleted: () => {
      setNotify({ msg: "Buy Tiktok Label successfully.", err: false });

      onClose && onClose();
      setDelay(() => {
        handleCompleted && handleCompleted();
      }, 1500);
    },
    onError: (err) => {
      setNotify({ msg: handleError(err?.toString()), err: true });
    },
  });

  const handleSubmit = React.useCallback(() => {
    if (!orderIDs || orderIDs.length === 0) return;

    toggleToast && toggleToast();
    setNotify && setNotify({ msg: null, err: true });
    buyLabel({
      variables: {
        orderIDs: orderIDs,
      },
    });
  }, [orderIDs, setNotify, toggleToast, buyLabel]);

  return (
    <Modal
      title="Buy Tiktok Label"
      open={open}
      onClose={onClose}
      sectioned
      primaryAction={{
        content: "Submit",
        onAction: handleSubmit,
        loading: loading,
      }}
      secondaryActions={[{ content: "Cancel", onAction: onClose }]}
    >
      <p>Are you sure to buy Tiktok Label?</p>
    </Modal>
  );
}
