import React, { useCallback, useState, useRef } from "react";
import {
	Heading,
	TextStyle,
	TextContainer,
	Badge,
	Button,
	Stack,
	RadioButton
} from "@shopify/polaris";
import styled from "styled-components";
import moment from "moment";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { GET_VARIABLES } from "../../../graphql/queries";
import { useQuery } from "@apollo/react-hooks";

const Container = styled.div`
.mb-flex {
	display: flex;
	flex-wrap: nowrap;
}
.mb-col-half {
	width: 50%;
}
.mb-flex-right .Polaris-Stack{
	justify-content: end;
}
.mb-transparent-table tr td:nth-child(2) {
	padding-left: 10px;
}

.mb-mt-10 {
	margin-top: 10px;
}
.mb-mt-20 {
	margin-top: 20px;
}
.qr-using-name {
	max-width: 350px;
	padding: 20px 0;
	text-align: center;
}
.mb-heading-underline h3.Polaris-Heading {
	text-decoration: underline;
}
@media screen and (max-width: 1000px) {
	.mb-flex {
		flex-wrap: wrap;
	}
	.mb-col-half {
		width: 100%;
	}
	.invoice-details {
		margin-top: 20px;
	}
}

.show-on-print {
	display: none;
}

`;

const STATUS_BADGE = {
	Pending: "info",
	Paid: "success",
	Overdue: "critical",
	Frozen: "critical",
	'Awaiting Approval': "warning",
};

const VALID_PAYMENT_METHOD = {
	'bank-transfer': 'Bank Transfer',
	'paypal': 'Paypal',
	'payoneer': 'Payoneer',
	'pingpong': 'Pingpong',
}

export const InvoiceDetailsPolaris = ({ invoice }) => {
	/** Get admin setting */
	const getAdminSettings = useQuery(GET_VARIABLES, {
		variables: {
			filter: {
				search: 'invoice_',
			},
		},
		fetchPolicy: "network-only",
	});
	let adminSettings = {}
	if (getAdminSettings?.data && getAdminSettings?.data?.variables && getAdminSettings?.data?.variables.length > 0) {
		getAdminSettings.data.variables.forEach(variable => {
			adminSettings[variable.key] = variable.value;
		});
	}
	/*
	Company: CÔNG TY TNHH CÔNG NGHỆ ONEMERCE
	Address: Lô 3.6 tầng 3 tháp A Tòa nhà Golden Palace, số 99 đường Mễ Trì, <br />Phường Mễ Trì, Quận Nam Từ Liêm, Thành phố Hà Nội, Việt Nam.
	Bank:
		ACB
		150320236666
		CÔNG TY TNHH CÔNG NGHỆ ONEMERCE
	*/
	/** END: Get admin setting */

	const [paymentMethod, setPaymentMethod] = useState(invoice && invoice.paymentMethod ? invoice.paymentMethod : "bank-transfer");

	const formatDate = (date) => {
		return moment(date).format(
			"YYYY-MM-DD HH:mm:ss"
		);
	}

	const handlePaymentMethodChange = useCallback((newValue) => {
		setPaymentMethod(newValue)
	}, [paymentMethod]);

	const paymentInstruction = (method, invoice) => {
		if (!invoice) {
			return;
		}
		let rootUserName = `${invoice.rootUser.firstName} ${invoice.rootUser.lastName}`;

		let rate = adminSettings?.invoice_payment_rate_1usd_to_vnd ? parseInt(adminSettings?.invoice_payment_rate_1usd_to_vnd) : 25390;
		let bankTransferAmount = Math.round(rate * invoice.totalPrice);

		let rateFormatted = rate.toLocaleString('en-US', {
			minimumFractionDigits: 0,
			maximumFractionDigits: 0
		});
		let bankTransferAmountFormatted = bankTransferAmount.toLocaleString('en-US', {
			minimumFractionDigits: 0,
			maximumFractionDigits: 0
		});

		let bankName = adminSettings?.invoice_payment_bank_name ? adminSettings?.invoice_payment_bank_name : '';
		let bankAccountNumber = adminSettings?.invoice_payment_bank_account_number ? adminSettings?.invoice_payment_bank_account_number : '';

		switch (method) {
			case 'bank-transfer':
				return (
					<div className="mb-mt-20">
						<Heading children={"Bank Transfer"} element="h5" />
						<p>To pay via bank transfer, please use the following bank account details:</p>
						<div className="mb-mt-20">
							<table className="mb-transparent-table">
								<tbody>
									<tr>
										<td><TextStyle variation="strong">Bank name:</TextStyle></td>
										<td>{bankName}</td>
									</tr>
									<tr>
										<td><TextStyle variation="strong">Bank account number:</TextStyle></td>
										<td>{bankAccountNumber}</td>
									</tr>
									<tr>
										<td><TextStyle variation="strong">Bank beneficiary:</TextStyle></td>
										<td>{adminSettings?.invoice_payment_bank_beneficiary ? adminSettings?.invoice_payment_bank_beneficiary : ''}</td>
									</tr>
									<tr>
										<td><TextStyle variation="strong">Amount:</TextStyle></td>
										<td> {rateFormatted} x {invoice.totalPrice} = {bankTransferAmountFormatted} VND</td>
									</tr>
								</tbody>
							</table>
						</div>

						<div>
							<div className="qr-using-name">
								<div><strong>-- Or --</strong></div>
								<div>Scan the QR bellow:</div>
							</div>
							<img
								src={`https://img.vietqr.io/image/${bankName}-${bankAccountNumber}-compact2.jpg?amount=${bankTransferAmount}&addInfo=MB - ${invoice.id} - ${rootUserName}`}
								style={{
									width: "350px",
									height: "auto",
									objectFit: "cover",
									display: "inline-block",
								}}
							/>

						</div>
					</div>
				);
			case 'paypal':
				return (
					<div className="mb-mt-20">
						<Heading children={"Paypal"} element="h5" />
						<p>To pay via PayPal, send the payment to our PayPal account at: <strong>{adminSettings?.invoice_payment_paypal_email ? adminSettings?.invoice_payment_paypal_email : ''}</strong></p>
					</div>
				);
			case 'payoneer':
				return (
					<div className="mb-mt-20">
						<Heading children={"Payoneer"} element="h5" />
						<p>To pay via Payoneer, send the payment to our Payoneer account at: <strong>{adminSettings?.invoice_payment_payoneer_email ? adminSettings?.invoice_payment_payoneer_email : ''}</strong></p>
					</div>
				);
			case 'pingpong':
				return (
					<div className="mb-mt-20">
						<Heading children={"Pingpong"} element="h5" />
						<p>To pay via Pingpong, send the payment to our Pingpong account at: <strong>{adminSettings?.invoice_payment_pingpong_email ? adminSettings?.invoice_payment_pingpong_email : ''}</strong></p>
					</div>
				);
			default:
				return <p>Please select a payment method.</p>;
		}
	};

	const contentRef = useRef();
	const handleExportPDF = async (invoice_id) => {
		const elementsToHide = document.querySelectorAll(".hide-on-print");
		elementsToHide.forEach((el) => {
			el.style.display = "none";
		});

		const elementsToShow = document.querySelectorAll(".show-on-print");
		elementsToShow.forEach((el) => {
			el.style.display = "block";
		});

		const element = contentRef.current;
		const canvas = await html2canvas(element, {
			scale: 2,
			useCORS: true,
		});
		const imgData = canvas.toDataURL("image/png");

		// Canvas size
		const canvasWidth = canvas.width;
		const canvasHeight = canvas.height;

		// PDF size
		const pdf = new jsPDF("portrait", "mm", "a4");
		const pdfWidth = pdf.internal.pageSize.getWidth();
		const pdfHeight = pdf.internal.pageSize.getHeight();

		// Padding
		const padding = 20; // Unit: mm

		// Calc with & height
		const availableWidth = pdfWidth - padding * 2;
		const availableHeight = pdfHeight - padding * 2;

		// Calc scale size to fit the pdf size
		const scaleFactor = Math.min(availableWidth / canvasWidth, availableHeight / canvasHeight);
		const imgWidth = canvasWidth * scaleFactor;
		const imgHeight = canvasHeight * scaleFactor;

		// Calc position with padding
		const xOffset = padding; // Padding from left
		const yOffset = padding; // Padding from top

		pdf.addImage(imgData, "PNG", xOffset, yOffset, imgWidth, imgHeight);
		pdf.save(`invoice-${invoice_id}.pdf`);

		elementsToHide.forEach((el) => {
			el.style.display = "";
		});
		elementsToShow.forEach((el) => {
			el.style.display = "";
		});
	};

	return (
		<Container ref={contentRef}>
			<TextContainer>
				<div className="mb-flex">
					<div className="mb-col-half">
						<Heading children={invoice.name} element="h3" />
					</div>

					<div className="mb-col-half mb-flex-right hide-on-print">
						<Stack alignment="right" >
							<Button onClick={() => handleExportPDF(invoice.id)}>
								Download PDF
							</Button>
						</Stack>
					</div>
				</div>

				<div className="mb-heading-underline">
					<Heading children={"From:"} element="h3" />
					<Heading children={adminSettings?.invoice_company_name ? adminSettings?.invoice_company_name : ''} element="h4" />
					<div dangerouslySetInnerHTML={{ __html: adminSettings?.invoice_company_address ? adminSettings?.invoice_company_address : '' }} />
				</div>


				<div className="mb-flex mb-heading-underline">
					<div className="mb-col-half billing-details">
						<Heading children={"To:"} element="h3" />
						<table className="mb-transparent-table">
							<tbody>
								<tr>
									<td><TextStyle variation="strong">Seller email:</TextStyle></td>
									<td>{invoice.rootUser?.email}</td>
								</tr>
								<tr>
									<td><TextStyle variation="strong">Seller name:</TextStyle></td>
									<td>{invoice.rootUser?.firstName} {invoice.rootUser?.lastName}</td>
								</tr>
								<tr>
									<td><TextStyle variation="strong">Seller team:</TextStyle></td>
									<td>{invoice.team.name}</td>
								</tr>
							</tbody>
						</table>
					</div>


					<div className="mb-col-half invoice-details">
						<Heading children={"Invoice Details:"} element="h3" />
						<table className="mb-transparent-table">
							<tbody>
								<tr>
									<td><TextStyle variation="strong">Invoice ID:</TextStyle></td>
									<td>#{invoice.id}</td>
								</tr>
								<tr className="hide-on-print">
									<td><TextStyle variation="strong">Status:</TextStyle></td>
									<td><Badge children={invoice.status} status={STATUS_BADGE[invoice.status]} /></td>
								</tr>
								<tr className="show-on-print">
									<td><TextStyle variation="strong">Status:</TextStyle></td>
									<td>{invoice.status}</td>
								</tr>
								<tr>
									<td><TextStyle variation="strong">Currency:</TextStyle></td>
									<td>USD</td>
								</tr>
								<tr>
									<td><TextStyle variation="strong">Created At:</TextStyle></td>
									<td>{formatDate(invoice.createdAt)}</td>
								</tr>
								<tr>
									<td><TextStyle variation="strong">Due At:</TextStyle></td>
									<td>{formatDate(invoice.paymentDueDate)}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>

				<div>
					<Heading children={"Invoice summary"} element="h3" />
					<div dangerouslySetInnerHTML={{ __html: invoice.summary }} />
				</div>

				{invoice.paymentMethod && invoice.paymentMethod.length > 0 && (
					<div>
						<Heading children={"Payment instructions"} element="h4" />
						<div className="mb-mt-10">
							{invoice.status == "Paid" ? (
								<label><strong>Choice payment method:</strong> {VALID_PAYMENT_METHOD[paymentMethod]}</label>
							) : (
								<>
									<label><strong>Choose payment method:</strong></label>
									<TextContainer>
										<Stack>
											<RadioButton
												label="Bank Transfer"
												checked={paymentMethod == 'bank-transfer'}
												value="bank-transfer"
												name="payment-method"
												onChange={() => handlePaymentMethodChange("bank-transfer")}
											/>
											{/* <RadioButton
												label="Paypal"
												checked={paymentMethod == 'paypal'}
												value="paypal"
												name="payment-method"
												onChange={() => handlePaymentMethodChange("paypal")}
											/> */}
											{/* <RadioButton
												label="Pingpong"
												checked={paymentMethod == 'pingpong'}
												value="pingpong"
												name="payment-method"
												onChange={() => handlePaymentMethodChange("pingpong")}
											/>
											<RadioButton
												label="Payoneer"
												checked={paymentMethod == 'payoneer'}
												value="payoneer"
												name="payment-method"
												onChange={() => handlePaymentMethodChange("payoneer")}
											/> */}
										</Stack>
									</TextContainer>
									<div>{paymentInstruction(paymentMethod, invoice)}</div>
								</>
							)}
						</div>
					</div>
				)}

				<hr />
				<div>
					<Heading children={"Payment terms"} element="h4" />
					<div dangerouslySetInnerHTML={{
						__html: `
						<p>1. Please ensure the payment is completed by the due date to avoid any interruptions.</p>
						<p>2. When making the bank transfer, please include Invoice ID as the payment reference so we can quickly identify your payment.</p>
						<p>3. Once the payment is complete, kindly send a payment confirmation or transaction receipt to <a href="mailto:support@merchbridge.com">support@merchbridge.com</a>.</p>
						<p>4. If you have any questions regarding your invoice or need assistance, please contact us at <a href="mailto:support@merchbridge.com">support@merchbridge.com</a>.</p>
						` }} />
				</div>

			</TextContainer>
		</Container>
	);
};
