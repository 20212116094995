import { Collapsible } from "@shopify/polaris";
import React from "react";
import _ from "lodash";

import { useTemplateCtx } from "../../templates/context";
import { strToSlug } from "../../../helper";
import SkeletonPagePolaris from "../../shared/SkeletonPagePolaris";
import image from "../../../assets/images/unnamed.jpeg";

export default function ViewTemplateActive({ node }) {
  const { id } = node || {};

  const { active, templateId, template, loading } = useTemplateCtx();
  const templateData = template?.data;
  const { bases, baseIds } = getBaseInfo(templateData);

  const isTemplate = id === templateId;
  const isLoading = loading && isTemplate;

  return (
    <Collapsible
      open={true === active ? isTemplate : active}
      id={`${templateId}`}
      transition={{ duration: "150ms", timingFunction: "ease" }}
    >
      {isLoading ? (
        <SkeletonPagePolaris sizeSmall />
      ) : baseIds?.length > 0 ? (
        baseIds.map((id, index) => {
          const pb = bases[id];
          if (!pb) return null;
          let product =
            templateData.products &&
            templateData.products.length &&
            templateData.products.find((p) => p.productbaseId === pb.id);

          let count = countState(product.variants);
          let templateProduct = product;
          let productVariants = templateProduct.variants;

          let templatePBase = pb;
          let templatePBaseVars = templatePBase["variants"];
          let templatePBaseAttr = templatePBase["attributes"];

          let buildProductData = reBuildProductData(
            productVariants,
            templatePBaseVars,
          );
          let productAttrs = getProductBaseAttrs(
            templatePBaseAttr,
            getActivatedProductAttrs(buildProductData),
          );
          const images = pb.images;
          return (
            <div key={`${id}-${index}`} className="bases-wrap">
              <div className="img-wrap">
                {images?.length > 0 ? (
                  images.map((file, idx) => (
                    <div key={`${id}-${idx}`} style={{ width: 200 }}>
                      <img
                        src={file.thumbnailUrl ? file.thumbnailUrl : file.url}
                        alt={""}
                        style={{ width: "100%" }}
                      />
                    </div>
                  ))
                ) : (
                  <div key={`${id}-image`} style={{ width: 200 }}>
                    <img src={image} alt={""} style={{ width: "100%" }} />
                  </div>
                )}
                <span style={{ marginTop: "1rem", display: "inline-block" }}>
                  {pb.baseCostDescription}
                </span>
              </div>
              <div>
                <span>{pb.title}</span>
                {productAttrs &&
                  Object.keys(productAttrs).map((attribute, index) => {
                    return (
                      <div key={`${index}-attribute-${attribute}`}>
                        <div className={"attribute-title"}>
                          {productAttrs[attribute] &&
                            productAttrs[attribute].name}
                          :
                        </div>
                        <div>
                          {productAttrs[attribute] &&
                            productAttrs[attribute]["options"] &&
                            Object.keys(productAttrs[attribute]["options"]).map(
                              (option, key) => {
                                let tagClassName =
                                  "attr-option-val pm-tag-enabled";
                                if (
                                  productAttrs[attribute]["options"][option][
                                    "disabled"
                                  ]
                                ) {
                                  tagClassName =
                                    "attr-option-val pm-tag-disabled";
                                }
                                return (
                                  <div
                                    key={`${index}-attr-option-${option}`}
                                    className={tagClassName}
                                  >
                                    {productAttrs[attribute]["options"] &&
                                      productAttrs[attribute]["options"][
                                        option
                                      ] &&
                                      productAttrs[attribute]["options"][
                                        option
                                      ]["label"]}
                                  </div>
                                );
                              },
                            )}
                        </div>
                      </div>
                    );
                  })}

                <div className={"variant-total"}>
                  {count && count.counDisabled >= 0 && (
                    <div>
                      Total enabled: <span>{count.counEnabled}</span>
                    </div>
                  )}
                  {count && count.counDisabled >= 0 && (
                    <div>
                      Total disabled: <span>{count.counDisabled}</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        })
      ) : null}
    </Collapsible>
  );
}

function getBaseInfo(templateData) {
  const bases = (templateData?.productBases || []).reduce((acc, item) => {
    if (!item || typeof item !== "object") return acc;
    acc[item.id] = item;
    return acc;
  }, {});
  const baseIds = templateData?.ProductBaseIds || [];

  return {
    bases,
    baseIds,
  };
}

function countState(templateProductVariants) {
  let counEnabled = 0;
  let counDisabled = 0;
  if (!templateProductVariants || templateProductVariants.length === 0) {
    return {
      counEnabled,
      counDisabled,
    };
  }

  templateProductVariants.forEach((val) => {
    if (!val.disabled) {
      counEnabled++;
    } else {
      counDisabled++;
    }
  });
  return {
    counEnabled,
    counDisabled,
  };
}

function reBuildProductData(productVariants, templatePBaseVars) {
  let buildProductData = [];

  if (!productVariants || productVariants.length === 0) {
    return buildProductData;
  }
  productVariants.forEach((val) => {
    let pBaseId = val.productBaseVariantID;
    (templatePBaseVars || []).forEach((v) => {
      if (v.id === pBaseId) {
        let pVariant = {
          attributes: v.attributes,
        };
        val["productBaseVariant"] = pVariant;
        val["productBaseVariantId"] = v.id;
        val["image"] = null;
      }
    });
    buildProductData.push(val);
  });
  return buildProductData;
}

function countActivatedVariants(variants) {
  let countActivated = 0;
  _.forOwn(variants, function (el, key) {
    if (
      el.productBaseVariant.attributes &&
      el.productBaseVariant.attributes.length > 0
    ) {
      if (!el.disabled) {
        countActivated++;
      }
    }
  });
  return countActivated;
}

function getProductBaseAttrs(productBaseAttrs, activateAttrs, variants) {
  let pBaseAttrs = {};

  let disabledAll = false;
  if (variants) {
    let countActivated = countActivatedVariants(variants);
    if (0 === countActivated) {
      disabledAll = true;
    }
  }

  if (productBaseAttrs && productBaseAttrs.length > 0) {
    productBaseAttrs.forEach((val) => {
      let attrOptions = {};
      val.options.forEach((opt) => {
        let disableVal = false;
        if (disabledAll) {
          disableVal = true;
        }
        if (
          activateAttrs &&
          activateAttrs[val.slug] &&
          activateAttrs[val.slug].length > 0
        ) {
          disableVal = true;
          if (_.includes(activateAttrs[val.slug], opt)) {
            disableVal = false;
          }
        }
        attrOptions[strToSlug(opt)] = {
          label: opt,
          disabled: disableVal,
        };
      });
      pBaseAttrs[val.slug] = {
        name: val.name,
        slug: val.slug,
        options: attrOptions,
      };
    });
  }
  return pBaseAttrs;
}

function getActivatedProductAttrs(variants) {
  let activatedAttrs = {};

  _.forOwn(variants, function (el, key) {
    if (
      !el.disabled &&
      el.productBaseVariant &&
      el.productBaseVariant.attributes &&
      el.productBaseVariant.attributes.length > 0
    ) {
      el.productBaseVariant.attributes.forEach((v) => {
        if (!activatedAttrs[v.slug]) {
          activatedAttrs[v.slug] = [];
        }
        activatedAttrs[v.slug].push(v.option);
      });
    }
  });
  return activatedAttrs;
}
