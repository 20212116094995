import { Frame } from "@shopify/polaris";
import React, { useCallback, useContext, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import SuspenseComp from "../components/shared/SuspenseComp";
import { ToastContextProvider } from "../components/shared/ToastContext";
import { AppContext, createContext } from "../context";
import routes from "../routers";
import SidebarPolaris from "./sidebar/SidebarPolaris";
import TopBarPolaris from "./topbar/TopBarPolaris";

const Container = styled.div`
  .Polaris-Frame__Content:has(.iframe-documentations) {
    background-color: #fff;
  }
`;

const FRAME_CONTEXT_NAME = "FrameContext";
const [FrameProvider, useFrameContext] = createContext(FRAME_CONTEXT_NAME);

const Banner = React.lazy(() => import("./Banner"));

const FramePolaris = (props) => {
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
  const skipToContentRef = useRef(null);

  const location = useLocation();
  const context = useContext(AppContext);
  const { currentUser } = context;

  const [bannerPadding, setBannerPadding] = useState(0);

  const checkTeamRoles = () => {
    return routes.find(
      (route) =>
        route.customLayout &&
        route.teamRoles &&
        route.teamRoles.includes(
          currentUser && currentUser.teamUser && currentUser.teamUser.role,
        ),
    );
  };

  let customLayout = false;
  if (checkTeamRoles() && "/" === location.pathname) {
    customLayout = true;
  }

  const skipToContentTarget = (
    <a id="SkipToContentTarget" ref={skipToContentRef} tabIndex={-1} />
  );

  const toggleMobileNavigationActive = useCallback(
    () =>
      setMobileNavigationActive(
        (mobileNavigationActive) => !mobileNavigationActive,
      ),
    [],
  );

  const renderNode = React.useCallback((node) => {
    if (!node) return;

    const logChanges = (records) => {
      let collap1 = 0;

      for (let record of records) {
        const target = record.target;
        if (!target || !target instanceof HTMLElement) continue;
        const collap = target.querySelector(
          ".mb-layout-wrap .collapsible-wrap",
        );

        if (collap) {
          const { height } = collap.getBoundingClientRect() || {};
          if (height > 0) collap1 = height;
        }
      }

      const layoutWrap = document.querySelector(".mb-layout-wrap");
      let screenOption = layoutWrap?.querySelector("#screen-options");
      let bannerWrapper = node.querySelector("#mb-banner");

      if (!bannerWrapper) return;
      let bannerPadding = 0;

      if (layoutWrap && screenOption) {
        const { height } = screenOption.getBoundingClientRect() || {};
        screenOption.style.position = "fixed";
        screenOption.style.top = "5.6rem";
        screenOption.style.width = "calc(100% - 24rem)";
        if (height > 0) bannerPadding = height;
      }

      if (collap1 > 0) {
        bannerPadding = 36 + collap1;
      }

      setBannerPadding(bannerPadding);
    };
    const observer = new MutationObserver(logChanges);
    observer.observe(node, { childList: true, subtree: true });
  }, []);

  return (
    <FrameProvider bannerPadding={bannerPadding}>
      <Helmet>
        {/* <title> {`${title ? `${title} - ` : ""}`}Merch Bridge</title> */}
        <title>Merch Bridge</title>
      </Helmet>
      <Container className="bm-frame">
        <Frame
          topBar={
            currentUser && (
              <TopBarPolaris
                {...props}
                toggleMobileNavigationActive={toggleMobileNavigationActive}
              />
            )
          }
          navigation={
            !customLayout &&
            currentUser && <SidebarPolaris {...props} location={location} />
          }
          showMobileNavigation={mobileNavigationActive}
          onNavigationDismiss={toggleMobileNavigationActive}
          skipToContentTarget={skipToContentRef.current}
        >
          {skipToContentTarget}
          <div
            className={currentUser && "p-4 sm:p-6"}
            id="mb-frame-wrap"
            ref={renderNode}
          >
            <SuspenseComp fallback={null}>
              <Banner />
            </SuspenseComp>
            <ToastContextProvider>{props.children}</ToastContextProvider>
          </div>
        </Frame>
      </Container>
    </FrameProvider>
  );
};

export default FramePolaris;

export { useFrameContext };
